import { computed, ref } from "vue"
import {
  ApiVitaminError,
  ApiVitaminGenerateResponse,
  ApiVitaminGenerationDataResponse,
  ApiVitaminLastGeneration,
  ApiVitaminLastGenerationStatus,
  ApiVitaminStatus,
  ApiVitaminStatusNames
} from "@/types/Vitamin"
import { vitaminController } from "@/api/vitamin/controller"
import { ApiNotificationType } from "@/types/Notifications"
import { AxiosError } from "axios"
import { useVitaminazerRecommendation } from "@/composables/vitaminazer/useVitaminazerRecommendation"

const isError = ref(false)
const isResult = ref(false)
const isHasPrevious = ref(false)
const lastResult = ref<ApiVitaminLastGeneration>()
const lastResultData = ref<ApiVitaminGenerationDataResponse>()
const status = ref<ApiVitaminStatus>()
const errorGenerateMessage = ref<string>()
const errorVitaminazer = ref<ApiVitaminError>()
const isSuccess = ref(false)
const isBlockedBtn = ref(false)

const isProcessing = computed<boolean>({
  get() {
    if (status.value) {
      return status.value.status === ApiVitaminStatusNames.PROCESSING
    }
    return false
  },
  set(newValue: boolean) {
    if (status.value && newValue) {
      status.value.status = ApiVitaminStatusNames.PROCESSING
    }
  }
})

export const useVitaminazer = () => {
  const { resetRecommendation } = useVitaminazerRecommendation()

  const resetState = () => {
    isProcessing.value = false
    isError.value = false
    isResult.value = false
    isSuccess.value = false
    errorGenerateMessage.value = undefined
    resetRecommendation()
  }

  const checkStatus = async () => {
    const res = await vitaminController.status()
    if (res) {
      status.value = res
      if (res.status === ApiVitaminStatusNames.PROCESSING) {
        isProcessing.value = true
      }
    }
  }

  const checkLastResult = async () => {
    if (!isProcessing.value) {
      const res = await vitaminController.findLast()
      if (res) {
        lastResult.value = res
        isHasPrevious.value = true
        isProcessing.value = res.status === ApiVitaminLastGenerationStatus.PROCESSING
      }
    }
  }

  const exploreResult = async () => {
    if (lastResult.value) {
      const res = await vitaminController.findOne(lastResult.value.id)
      if (res) {
        isResult.value = true
        lastResultData.value = res
        if (res.status === ApiVitaminLastGenerationStatus.FAILED) {
          isError.value = true
          errorVitaminazer.value = res.error!
        }
        if (res.status === ApiVitaminLastGenerationStatus.SUCCEEDED) {
          isSuccess.value = true
        }
      }
    }
  }

  const generate = async (url: string, siteId?: number) => {
    try {
      const res = await vitaminController.postGenerate({ url: url, siteId })
      const generation = res as Exclude<ApiVitaminLastGeneration, null>
      resetState()
      if (generation.status === ApiVitaminLastGenerationStatus.PROCESSING) {
        isProcessing.value = true
      }
      if (generation.status === ApiVitaminLastGenerationStatus.COMPLETED) {
        isResult.value = true
      }
      if (generation.status === ApiVitaminLastGenerationStatus.FAILED) {
        isResult.value = true
        isError.value = true
        errorVitaminazer.value = generation.error!
      }
      lastResult.value = generation
    } catch (e: any) {
      const error = e as AxiosError<Exclude<ApiVitaminGenerateResponse, ApiVitaminLastGeneration>>
      errorGenerateMessage.value = error.response?.data?.message
    } finally {
      isBlockedBtn.value = false
    }
  }

  const subscribeVitaminNotification = () => {
    window.addEventListener(ApiNotificationType.VITAMIN_COMPLETED, async (_e: Event) => {
      await checkStatus()
      await checkLastResult()
      await exploreResult()
    })

    window.addEventListener(ApiNotificationType.VITAMIN_FAILED, async (_e: Event) => {
      await checkStatus()
      await checkLastResult()
      await exploreResult()
    })
  }

  return {
    isError,
    isResult,
    isHasPrevious,
    lastResult,
    lastResultData,
    status,
    errorGenerateMessage,
    isProcessing,
    checkStatus,
    checkLastResult,
    exploreResult,
    resetState,
    subscribeVitaminNotification,
    errorVitaminazer,
    isSuccess,
    isBlockedBtn,
    generate
  }
}
