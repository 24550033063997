import { computed, DefineComponent, h, ref } from "vue"
import { Discount } from "@/types/Discounts"
import { isHasNotUsedDiscounts as _isHasNotUsedDiscounts } from "@/utils/discounts"
import { Modal } from "ant-design-vue"
import { BonusModal, ExpiredBonusModal } from "@/components"
import { ModalFunc } from "ant-design-vue/lib/modal/Modal"
import { discountsController } from "@/api/discounts/controller"
import { AxiosError } from "axios/index"
import { useLimits } from "@/composables/useLimits"

const discounts = ref<Discount[]>([])
const accountAlreadyActivated = ref<boolean>(false)
export const useDiscounts = () => {
  const { requestLimits } = useLimits()
  let modal: ReturnType<ModalFunc>

  const setNeedCheckDiscountsInLocalStorage = (id: string | undefined | null) => {
    if (!id) return
    localStorage.setItem("needCheckDiscounts", id)
  }

  const isNeedCheckDiscountsInLocalStorage = (): boolean => {
    const value = localStorage.getItem("needCheckDiscounts")
    return !!value
  }

  const getDiscountIdIFromLocalStorage = (): string | null => {
    return localStorage.getItem("needCheckDiscounts")
  }

  const deletedNeedCheckDiscountsInLocalStorage = () => {
    localStorage.removeItem("needCheckDiscounts")
  }

  const getDiscounts = (): Discount[] => {
    return discounts.value || []
  }

  const setDiscounts = (newDiscounts: Discount[]): Discount[] => {
    discounts.value = [...newDiscounts]
    return getDiscounts()
  }

  const isHasNotUsedDiscounts = computed(() => {
    return _isHasNotUsedDiscounts(discounts.value)
  })

  const accountIsNotActivated = computed(() => {
    return !accountAlreadyActivated.value
  })

  const closeModal = () => {
    if (modal) {
      modal.destroy()
    }
    deletedNeedCheckDiscountsInLocalStorage()
  }

  const openModal = (modalComponent: DefineComponent) => (options?: { onOk?: (() => void) | (() => Promise<void>) }) => {
    modal = Modal.confirm({
      closable: true,
      maskClosable: true,
      class: "bonus-modal-container",
      width: "548px",
      modalRender: () =>
        h(
          "div",
          { class: "ant-modal-content" },
          h(modalComponent, {
            onOk: async () => {
              if (options && options.onOk) {
                await options.onOk()
                closeModal()
              } else {
                closeModal()
              }
            }
          })
        )
    })
  }

  const openBonusModal = (options?: { onOk?: (() => void) | (() => Promise<void>) }) => {
    openModal(BonusModal as DefineComponent)(options)
  }

  const openExpiredBonusModal = (options?: { onOk?: (() => void) | (() => Promise<void>) }) => {
    openModal(ExpiredBonusModal as DefineComponent)(options)
  }

  const requestDiscounts = async () => {
    const res = await discountsController.findAll()
    if (res) {
      setDiscounts(res)
    }
  }

  const updateDiscounts = async () => {
    await requestDiscounts()
    if (isHasNotUsedDiscounts.value) {
      openBonusModal({
        onOk: closeModal
      })
    } else {
      openExpiredBonusModal()
    }
  }

  const useDiscountToOrder = async (orderId: string, discount: Discount) => {
    const res = await discountsController.useDiscountToOrder({ discount_id: discount.id, order_uuid: orderId })
    await requestDiscounts()
    return res
  }

  const activateDiscount = async () => {
    accountAlreadyActivated.value = true
    const discountId = getDiscountIdIFromLocalStorage()
    await requestDiscounts()
    try {
      if (discounts.value.length === 0 && isNeedCheckDiscountsInLocalStorage() && discountId) {
        const res = await discountsController.activateDiscount({ key: discountId })
        if (res) {
          await updateDiscounts()
        }
      } else if (isNeedCheckDiscountsInLocalStorage()) {
        deletedNeedCheckDiscountsInLocalStorage()
      }
      if (discounts.value.length) {
        await requestLimits()
      }
    } catch (error: any) {
      const e = error as AxiosError
      if (e.response?.status === 422) {
        openExpiredBonusModal()
      }
    } finally {
      deletedNeedCheckDiscountsInLocalStorage()
    }
  }

  return {
    setNeedCheckDiscountsInLocalStorage,
    isNeedCheckDiscountsInLocalStorage,
    setDiscounts,
    getDiscounts,
    discounts,
    isHasNotUsedDiscounts,
    openBonusModal,
    closeModal,
    requestDiscounts,
    useDiscountToOrder,
    activateDiscount,
    accountAlreadyActivated,
    accountIsNotActivated
  }
}
