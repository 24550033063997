import { computed, nextTick, ref } from "vue"
import { useFetchGetAllNotifications } from "@/api/notifications"
import { Notification } from "@/types/Notifications"

const isShow = ref(false)
const notifications = ref<Notification[]>()
const currentPage = ref(1)
const lastPage = ref()
export const useNotificaitonDrawer = () => {
  const open = async () => {
    isShow.value = true
    document.body.style.overflow = "hidden"
    // await requestNotifications()
  }

  const isResolveCurrentPage = computed<boolean>((): boolean => {
    if (lastPage.value) {
      return currentPage.value <= lastPage.value
    }
    return true
  })

  const notReadNotificationsCount = computed<number>(() => {
    if (notReadNotifications.value) {
      return notReadNotifications.value.length
    }
    return 0
  })

  const notReadNotifications = computed<Notification[]>(() => {
    if (notifications.value) {
      return notifications.value.filter((n) => n.read_at === null)
    }
    return []
  })

  const requestNotifications = async () => {
    if (isResolveCurrentPage.value || currentPage.value === 1) {
      const notificationRes = await useFetchGetAllNotifications({ page: currentPage.value })
      if (notificationRes) {
        lastPage.value = notificationRes.meta.last_page
        if (notifications.value && notifications.value.length && currentPage.value !== 1) {
          notifications.value = [...notifications.value, ...notificationRes.data]
        } else {
          notifications.value = []
          await nextTick(() => {
            notifications.value = [...notificationRes.data]
          })
        }
        if (notificationRes.meta.current_page !== currentPage.value) {
          currentPage.value = notificationRes.meta.current_page
        }
      }
    }
    notReadNotificationsCount.effect.run()
  }

  const close = () => {
    isShow.value = false
    currentPage.value = 1
    document.body.style.overflow = ""
  }

  const toggle = async () => {
    if (isShow.value) {
      close()
    } else {
      await open()
    }
  }

  return {
    isShow,
    open,
    close,
    toggle,
    notifications,
    requestNotifications,
    currentPage,
    lastPage,
    notReadNotificationsCount,
    notReadNotifications
  }
}
