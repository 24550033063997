import { App as VApp, createApp, ref } from "vue"
import { getDecoratorBase } from "@/api/decorators"
import "reflect-metadata"
import App from "./App.vue"
import router from "./router"
// import "bootstrap/scss/bootstrap.scss"
import * as Sentry from "@sentry/vue"
import { metaTitleDirective } from "@/directives/metaTitle"
import "@/assets/scss/style.scss"

const app = createApp(App)

const viewportPlugin = {
  install: (app: VApp<unknown>) => {
    const isXsViewport = (clientWidth: number): boolean => clientWidth >= 0 && clientWidth < 576
    const isSmViewport = (clientWidth: number): boolean => clientWidth >= 576 && clientWidth < 768
    const isMdViewport = (clientWidth: number): boolean => clientWidth >= 768 && clientWidth < 800
    const isLgViewport = (clientWidth: number): boolean => clientWidth >= 800 && clientWidth < 1200
    const isXlViewport = (clientWidth: number): boolean => clientWidth >= 1200 && clientWidth < 1400
    const isXXlViewport = (clientWidth: number): boolean => clientWidth >= 1400

    const getViewportName = (clientWidth: number): "xs" | "sm" | "md" | "lg" | "xl" | "xxl" => {
      if (isXsViewport(clientWidth)) {
        return "xs"
      }
      if (isSmViewport(clientWidth)) {
        return "sm"
      }
      if (isMdViewport(clientWidth)) {
        return "md"
      }
      if (isLgViewport(clientWidth)) {
        return "lg"
      }
      if (isXlViewport(clientWidth)) {
        return "xl"
      }
      if (isXXlViewport(clientWidth)) {
        return "xxl"
      }
      return "xxl"
    }

    app.config.globalProperties.$viewport = ref({
      viewport: getViewportName(window.document.documentElement.clientWidth),
      isMobile:
        isXsViewport(window.document.documentElement.clientWidth) ||
        isSmViewport(window.document.documentElement.clientWidth) ||
        isMdViewport(window.document.documentElement.clientWidth)
    })
    app.provide("viewport", app.config.globalProperties.$viewport)
    window.addEventListener("resize", () => {
      app.config.globalProperties.$viewport.value = {
        viewport: getViewportName(window.document.documentElement.clientWidth),
        isMobile:
          isXsViewport(window.document.documentElement.clientWidth) ||
          isSmViewport(window.document.documentElement.clientWidth) ||
          isMdViewport(window.document.documentElement.clientWidth)
      }
    })
    app.provide("viewport", app.config.globalProperties.$viewport)
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(viewportPlugin)

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logErrors: true,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(router)
app.directive("meta-title", metaTitleDirective)
app.mount("#app")
