import {
  ApiAuthGoogleCallbackPayload,
  ApiAuthGoogleCallbackResponse,
  ApiAuthGoogleResponse,
  ApiAuthLogoutResponse
} from "@/types/Auth"
import { authService } from "@/api/auth/service"

export const useFetchGoogleAuth = async (): Promise<ApiAuthGoogleResponse> =>
  (await authService.getGoogleAuthLink()) as ApiAuthGoogleResponse

export const useFetchGoogleCallback = async (payload: ApiAuthGoogleCallbackPayload): Promise<ApiAuthGoogleCallbackResponse> =>
  (await authService.getGoogleCallback(payload)) as ApiAuthGoogleCallbackResponse

export const useFetchLogout = async (): Promise<ApiAuthLogoutResponse> => (await authService.logout()) as ApiAuthLogoutResponse
