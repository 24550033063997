import { Get, Service } from "@/api/decorators"
import { ApiClient } from "@/types/Client"

enum Modules {
  PROFILE = "/profile"
}

@Service({
  path: "clients"
})
export class ApiClientService {
  @Get(Modules.PROFILE)
  public async getProfile(): Promise<ApiClient | undefined | void> {}
}
