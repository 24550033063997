import { Get, Post, QueryParams, Service } from "@/api/decorators"
import { ApiClient } from "@/types/Client"
import { ApiServices } from "@/api"
import {
  ApiNotificationsQueryParams,
  ApiNotificationsReadNotificaitionsPayload,
  ApiNotificationsResponse,
  ApiNotificationsUnsubscribeResponse
} from "@/types/Notifications"

enum Modules {
  UNSUBSCRIBE = "/unsubscribe",
  READ = "/read",
  SUBSCRIBE = "/subscribe"
}

@Service({
  path: "notifications"
})
class ApiNotificationsService {
  @Get()
  public async findAll(
    @QueryParams() params?: ApiNotificationsQueryParams
  ): Promise<ApiNotificationsResponse | undefined | void> {}

  @Post(Modules.UNSUBSCRIBE)
  public async unsubscribe(): Promise<ApiNotificationsUnsubscribeResponse | undefined | void> {}

  @Post(Modules.SUBSCRIBE)
  public async subscribe(): Promise<ApiNotificationsUnsubscribeResponse | undefined | void> {}

  @Post(Modules.READ)
  public async readNotifications(
    ids?: ApiNotificationsReadNotificaitionsPayload
  ): Promise<ApiNotificationsUnsubscribeResponse | undefined | void> {}
}

export const notificationsService = new ApiNotificationsService()
