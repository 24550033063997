import { ApiDiscountService } from "@/api/discounts/service"
import { Controller } from "@/api/decorators"
import BaseController from "@/api/base-controller"
import {
  ApiActivateDiscountPayload,
  ApiAddDiscountPayload,
  ApiAddDiscountResponse,
  ApiDiscountsResponse,
  ApiUseDiscountResponse
} from "@/types/Discounts"

@Controller(ApiDiscountService)
class ApiDiscountController extends BaseController<ApiDiscountService> {
  public async findAll(): Promise<ApiDiscountsResponse | undefined | void> {
    return this.service.findAll()
  }

  public async activateDiscount(payload: ApiAddDiscountPayload): Promise<ApiAddDiscountResponse | undefined | void> {
    return this.service.activateDiscount(payload)
  }

  public async useDiscountToOrder(payload: ApiActivateDiscountPayload): Promise<ApiUseDiscountResponse | undefined | void> {
    return this.service.useDiscountToOrder(payload)
  }
}

export const discountsController = new ApiDiscountController()
