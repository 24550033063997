import { Controller } from "@/api/decorators"
import BaseController from "@/api/base-controller"
import { ApiVitaminService } from "@/api/vitamin/service"
import {
  ApiVitaminDataPayload,
  ApiVitaminGeneratePayload,
  ApiVitaminGenerateResponse,
  ApiVitaminGenerationDataResponse,
  ApiVitaminGroupQueriesDataResponse,
  ApiVitaminLastGeneration,
  ApiVitaminQueriesDataResponse,
  ApiVitaminRecommendationResponse,
  ApiVitaminStatus,
  ApiVitaminUnigramDataResponse
} from "@/types/Vitamin"

@Controller(ApiVitaminService)
class ApiVitaminController extends BaseController<ApiVitaminService> {
  public async findOne(id: string | number): Promise<ApiVitaminGenerationDataResponse | undefined | void> {
    return this.service.findOne(`${id}`)
  }

  public async status(): Promise<ApiVitaminStatus | undefined | void> {
    return this.service.status()
  }

  public async postGenerate(payload: ApiVitaminGeneratePayload): Promise<ApiVitaminGenerateResponse | undefined | void> {
    return this.service.postGenerate(payload)
  }

  public async findLast(): Promise<ApiVitaminLastGeneration | undefined | void> {
    return this.service.findLast()
  }

  public async findQueriesData(
    id: string | number,
    params?: ApiVitaminDataPayload
  ): Promise<ApiVitaminQueriesDataResponse | undefined | void> {
    return this.service.findQueriesData(id, params)
  }

  public async findUnigramData(
    id: string | number,
    params?: ApiVitaminDataPayload
  ): Promise<ApiVitaminUnigramDataResponse | undefined | void> {
    return this.service.findUnigramData(id, params)
  }

  public async findGroupQueriesData(
    id: string | number,
    params?: ApiVitaminDataPayload
  ): Promise<ApiVitaminGroupQueriesDataResponse | undefined | void> {
    return this.service.findGroupQueriesData(id, params)
  }

  public async findRecommendation(): Promise<ApiVitaminRecommendationResponse | undefined | void> {
    return this.service.findRecommendation()
  }
}

export const vitaminController = new ApiVitaminController()
