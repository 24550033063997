import { defineComponent, h, Teleport } from "vue"

export default defineComponent({
  props: {},
  setup() {
    // return () => h(Teleport, { to: "body", id: "notification-container" }, [])
    return () =>
      h(Teleport, { to: "body" }, [
        h(
          "div",
          {
            class:
              "notification-container position-fixed bottom-0 end-0 me-0 me-lg-16 mb-0 mb-lg-12 px-4 px-sm-10 px-lg-0 d-flex flex-column",
            id: "notification-container",
            style: {
              zIndex: 10000
            }
          },
          []
        )
      ])
  }
})
