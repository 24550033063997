import { ApiQueryParams, ApiResponse } from "@/api/types"
import { ApiReportGroupCountry, ApiReportInGroup, ApiReportMetric, ApiReportSite } from "@/types/Reports"

export type ApiOrder = {
  uuid: string
  site_id: number
  status: ApiOrderStatus
  created_at: Date | string | null
  updated_at: Date | string | null
  deleted_at: Date | string | null
  read_at: Date | string | null
  metrics: ApiReportMetric[]
  is_paid: boolean
  type: string
  site: ApiOrderSite
  products: ApiOrderProduct[]
  country: ApiOrderCountry
  payment_status: ApiOrderPaymentStatus | null
}

export type ApiOrderGroup = {
  uuid: string
  name: string
  site: ApiReportSite
  created_at: Date | string | null
  reports: ApiReportInGroup[]
  metrics: ApiReportMetric[]
  order: {
    uuid: string
  }
  country: ApiReportGroupCountry
}

export enum ApiOrderPaymentStatus {
  WAITING = "waiting",
  CANCELED = "canceled",
  REFUNDED = "refunded",
  CHARGEBACK = "chargeback",
  PROCESSED = "processed"
}

export type ApiOrderCountry = {
  id: number
  code: string
  name: string
}
export enum ApiOrderStatus {
  DRAFT = "draft",
  COMPLETED = "completed",
  FAILED = "failed",
  CANCELED = "cancelled",
  PROCESSING = "processing"
}

export type ApiOrderProduct = {
  uuid: string
  name: string
  report_group_uuid?: string | null
  status: ApiOrderStatus
  status_error_message: string
  metrics: ApiReportMetric[]
}

export type ApiOrderSite = {
  id: number
  url: string
}

export type ApiOrdersGetOrderByIdPayload = {
  id: ApiOrder["uuid"]
}

export type ApiOrdersPostNewOrderPayload = {
  site_id: string | number
}

export type ApiOrdersPostExecuteOrderPayload = {
  order: string | number
}

export type ApiOrdersLaunchOrderPayload = {
  site_id: string | number
  country_id: string | number
}

export type ApiOrdersQueryParams = ApiQueryParams<ApiOrder & { site_url: string; sort: string }>
export type ApiOrdersSerializer = (items: ApiOrder[] | undefined) => ApiOrder[] | undefined
export type ApiOrderSerializer = (item: ApiOrder | undefined) => ApiOrder | undefined
export type ApiOrdersResponse = ApiResponse<ApiOrder[]>

export type Order = ApiOrder
export type OrderProduct = ApiOrderProduct
