import { DefineComponent, getCurrentInstance, h, reactive } from "vue"
import { Modal } from "ant-design-vue"
import { ModalFunc } from "ant-design-vue/lib/modal/Modal"
import { SelectCountryModal } from "@/components"
import { ApiSiteCountryItem, Site } from "@/types/Sites"
import { useViewport } from "@/composables/useViewport"

const selectedCountries = reactive<Map<string | number, ApiSiteCountryItem>>(new Map())

export const useSelectCountriesModal = () => {
  let modal: ReturnType<ModalFunc>
  const appContext = getCurrentInstance()?.appContext
  const { viewport } = useViewport()

  const closeModal = () => {
    if (modal) {
      modal.destroy()
    }
  }

  const openModal =
    (modalComponent: DefineComponent) =>
    (options?: { site: Site; onClose?: () => Promise<void> | void; onLaunch: () => Promise<void> | void }) => {
      modal = Modal.confirm({
        closable: true,
        maskClosable: true,
        class: "select-country-container",
        width: viewport.value.isMobile ? "100%" : "548px",
        wrapClassName: "select-country-wrapper",
        modalRender: () =>
          h(
            "div",
            { class: "ant-modal-content" },
            h(modalComponent, {
              onClose: async () => {
                if (options && options.onClose) {
                  await options.onClose()
                  closeModal()
                } else {
                  closeModal()
                }
              },
              onLaunch: async () => {
                await options?.onLaunch()
                if (options && options.onClose) {
                  await options.onClose()
                  closeModal()
                } else {
                  closeModal()
                }
              },
              site: options?.site
            })
          ),
        appContext
      })
    }

  const openSelectCountriesModal = (options?: {
    site: Site
    onClose?: () => Promise<void> | void
    onLaunch: () => Promise<void> | void
  }) => {
    openModal(SelectCountryModal as DefineComponent)(options)
  }

  return {
    openSelectCountriesModal,
    closeModal,
    selectedCountries
  }
}
