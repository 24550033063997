import {
  ApiOrder,
  ApiOrdersLaunchOrderPayload,
  ApiOrdersPostExecuteOrderPayload,
  ApiOrdersPostNewOrderPayload,
  ApiOrdersQueryParams,
  ApiOrdersResponse
} from "@/types/Orders"
import "reflect-metadata"
import { Get, PathParam, Post, QueryParams, Service } from "@/api/decorators"

@Service({
  path: "orders"
})
export class ApiOrderService {
  @Get()
  public async findAll(@QueryParams() params?: ApiOrdersQueryParams): Promise<ApiOrdersResponse | undefined | void> {}

  @Get("/:id")
  public async findOne(@PathParam("id") id: string): Promise<ApiOrder | undefined | void> {}

  @Post()
  public async createOrder(payload: ApiOrdersPostNewOrderPayload): Promise<ApiOrder | undefined | void> {}

  @Post("/:id/execute")
  public async executeOrder(
    @PathParam("id") id: string,
    payload: ApiOrdersPostExecuteOrderPayload
  ): Promise<ApiOrder | undefined | void> {}

  @Post("/launch")
  public async launchOrder(payload: ApiOrdersLaunchOrderPayload): Promise<{ message: string } | undefined | void> {}
}
