import { computed, ref } from "vue"
import { ApiSitesQueryParams, Site } from "@/types/Sites"
import { useSitesSort } from "@/composables/useSitesSort"
import { sitesController } from "@/api/sites/controller"
import { Routes } from "@/router"
import { useRouter } from "vue-router"
import { clientController } from "@/api/client/controller"

const sites = ref<Site[]>()
const unavailableSites = ref<Site[]>()
const unavailableTotal = ref<number>()
const page = ref(1)
const lastPage = ref()
const sitesUpdating = ref<boolean>()
export const useSites = () => {
  const router = useRouter()
  const { sort, initSortFromLocalStorage } = useSitesSort()
  const searchText = ref("")
  const loadingSiteId = ref<number>()
  const isLoading = ref(true)

  const nextPage = (): number => {
    page.value += 1
    return page.value
  }

  const isResolveNextPage = (): boolean => {
    return page.value < lastPage.value
  }

  const isEmptySites = (sites: Site[] | undefined): boolean => {
    if (!sites) return true
    return sites.length === 0
  }

  const params = computed<ApiSitesQueryParams>(() => {
    if (searchText.value) {
      return {
        page: page.value,
        sort: sort.value?.id as any,
        "filter[name]": searchText.value
      }
    }
    return {
      page: page.value,
      sort: sort.value?.id as any
    }
  })

  const unavailableParams = computed<ApiSitesQueryParams>(() => {
    if (searchText.value) {
      return {
        per_page: -1,
        "filter[name]": searchText.value
      }
    }
    return {
      per_page: -1
    }
  })

  const unavailableSitesAlreadyLoaded = computed<boolean>(() => {
    return !!unavailableSites.value
  })

  const sitesAlreadyLoaded = computed<boolean>(() => {
    return !!sites.value
  })

  const loadSites = async () => {
    initSortFromLocalStorage()
    const sitesRes = await sitesController.findAll(params.value)
    if (sitesRes && sitesRes.data) {
      sitesUpdating.value = sitesRes.updating
      lastPage.value = sitesRes.meta.last_page
      if (sites.value) {
        sites.value = [...sites.value!, ...sitesRes.data]
      } else {
        sites.value = [...sitesRes.data]
      }
    }
  }

  const checkUnavailableSites = async (): Promise<boolean> => {
    await loadUnavailableSites()
    if (isEmptySites(unavailableSites.value) && !searchText.value) {
      await router.push(`${Routes.SIGN_IN}?code=sites`)
      return false
    } else {
      return true
    }
  }

  const checkSites = async (): Promise<boolean> => {
    await loadSites()
    if (sitesUpdating.value) {
      return true
    }
    if (isEmptySites(sites.value) && !searchText.value) {
      return await checkUnavailableSites()
    } else {
      return true
    }
  }

  const loadUnavailableSites = async () => {
    const sitesRes = await sitesController.findAllUnavailable(unavailableParams.value)
    if (sitesRes) {
      unavailableSites.value = sitesRes.data
      unavailableTotal.value = sitesRes.meta.total
    }
  }

  const reloadSites = async () => {
    isLoading.value = true
    page.value = 1
    sites.value = []
    unavailableSites.value = []
    await loadSites()
    await loadUnavailableSites()
    isLoading.value = false
  }

  const updateSite = async (siteId: Site["id"]) => {
    loadingSiteId.value = siteId
    const siteRes = await sitesController.findOne(siteId)
    if (sites.value && siteRes) {
      const idx = sites.value.findIndex((s) => s.id === siteId)
      if (idx >= 0) {
        sites.value[idx] = siteRes
        sites.value = [...sites.value]
      }
    }
    loadingSiteId.value = undefined
  }

  let timeout: number
  const listenSitesUpdated = async () => {
    if (sitesUpdating.value) {
      timeout = setTimeout(async () => {
        await reloadSites()
        await listenSitesUpdated()
      }, 1000)
    } else {
      clearInterval(timeout)
      if (isEmptySites(sites.value) && isEmptySites(unavailableSites.value)) {
        await router.push(`${Routes.SIGN_IN}?code=sites`)
      }
    }
  }

  return {
    sites,
    unavailableSites,
    unavailableTotal,
    page,
    lastPage,
    loadingSiteId,
    searchText,
    nextPage,
    isLoading,
    isResolveNextPage,
    checkUnavailableSites,
    checkSites,
    loadSites,
    unavailableSitesAlreadyLoaded,
    sitesAlreadyLoaded,
    loadUnavailableSites,
    reloadSites,
    updateSite,
    sitesUpdating,
    listenSitesUpdated
  }
}
