import { TableColumnsType } from "ant-design-vue"
import { SegmentButtonItem, Viewport } from "@/types/common"
import { ApiQueryParams } from "@/api/types"
import { ApiReportData, ApiReportDataType, ApiReportFilter, ApiReportMetric, ApiReportMetricsEnum } from "@/types/Reports"
import { useCurrentSite } from "@/composables/useCurrentSite"
import { sitesController } from "@/api/sites/controller"
import { reportsController } from "@/api/reports/controller"
import { ordersController } from "@/api/orders/controller"

export const computeColumnsByTypeTable = (typeTable: SegmentButtonItem | undefined, viewport: Viewport): TableColumnsType => {
  if (typeTable && typeTable.value === "without") {
    return [
      {
        title: "Query",
        dataIndex: "query_name",
        key: "query_name",
        fixed: "left",
        width: viewport.isMobile ? 180 : 312
      },
      {
        title: "For the last 30 days",
        key: "last_30_days",
        children: [
          {
            title: "Position",
            dataIndex: "position",
            key: "position_child",
            width: 100,
            sorter: true
          },
          {
            title: "Clicks",
            dataIndex: "clicks",
            key: "clicks_child",
            width: 100,
            sorter: true
          },
          {
            title: "CTR",
            dataIndex: "ctr",
            key: "ctr_child",
            width: 100,
            sorter: true
          }
        ]
      },
      {
        title: "For the month with the maximum position",
        key: "month",
        children: [
          {
            title: "Month with the best position",
            dataIndex: "best_position_month",
            key: "best_position_month_child",
            width: 140,
            sorter: true
          },
          {
            title: "Position",
            dataIndex: "best_position",
            key: "best_position_child",
            width: 140,
            sorter: true
          },
          {
            title: "Clicks",
            dataIndex: "best_position_clicks",
            key: "best_position_clicks_child",
            width: 100,
            sorter: true
          },
          {
            title: `Best position\nCTR`,
            dataIndex: "best_position_ctr",
            key: "best_position_ctr_child",
            width: 140,
            sorter: true
          },
          {
            title: `Impressions`,
            dataIndex: "best_position_impressions",
            key: "best_position_impressions_child",
            width: 140,
            sorter: true
          }
        ]
      }
    ]
  }
  return [
    {
      title: "Query",
      dataIndex: "query_name",
      key: "query_name",
      fixed: "left",
      width: viewport.isMobile ? 180 : 312
    },
    {
      title: "For the last 30 days",
      key: "last_30_days",
      children: [
        {
          title: "Position",
          dataIndex: "position",
          key: "position_child",
          width: 100,
          sorter: true
        },
        {
          title: "Clicks",
          dataIndex: "clicks",
          key: "clicks_child",
          width: 100,
          sorter: true
        },
        {
          title: "CTR",
          dataIndex: "ctr",
          key: "ctr_child",
          width: 100,
          sorter: true
        },
        {
          title: "Impressions",
          dataIndex: "impressions",
          key: "impressions_child",
          width: 120,
          sorter: true
        }
      ]
    },
    {
      title: "For the month with the maximum position",
      key: "month",
      children: [
        {
          title: "Month with the best position",
          dataIndex: "best_position_month",
          key: "best_position_month_child",
          width: 140,
          sorter: true
        },
        {
          title: "Position",
          dataIndex: "best_position",
          key: "best_position_child",
          width: 140,
          sorter: true
        },
        {
          title: "Clicks",
          dataIndex: "best_position_clicks",
          key: "best_position_clicks_child",
          width: 100,
          sorter: true
        },
        {
          title: `Best position\nCTR`,
          dataIndex: "best_position_ctr",
          key: "best_position_ctr_child",
          width: 140,
          sorter: true
        },
        {
          title: `Impressions`,
          dataIndex: "best_position_impressions",
          key: "best_position_impressions_child",
          width: 140,
          sorter: true
        }
      ]
    },
    { title: "Missed\ntraffic", dataIndex: "lost_traffic", key: "lost_traffic", width: 140, sorter: true },
    { title: "Position\ndifference", dataIndex: "position_diff", key: "position_diff", width: 140, sorter: true }
  ]
}

type RequestReportDataResult = {
  data: ApiReportData[]
  total: number
  reportType: ApiReportDataType | undefined
}
export const fetchReportData = async (params: ApiQueryParams<ApiReportData>): Promise<RequestReportDataResult> => {
  const result: RequestReportDataResult = {
    data: [],
    total: 0,
    reportType: undefined
  }
  const { currentReportId } = useCurrentSite()
  if (currentReportId.value) {
    const reportDataRes = await reportsController.findData(currentReportId.value, params)
    if (reportDataRes && reportDataRes.data) {
      result.data = reportDataRes.data
      result.total = reportDataRes.meta.total
      result.reportType = reportDataRes.report?.type_key || ""
    }
  }

  return result
}

export const fetchReportGroupAndSite = async (id: string) => {
  const { setCurrentReportGroup, currentReportGroup, setCurrentSite } = useCurrentSite()
  const reportGroupRes = await reportsController.findOneByOrderProduct(id)
  if (reportGroupRes) {
    setCurrentReportGroup(reportGroupRes)
  }
  if (currentReportGroup.value) {
    const siteRes = await sitesController.findOne(currentReportGroup.value.site.id)
    if (siteRes) {
      setCurrentSite(siteRes)
    }
  }
}

export const getColClassByIdx = (idx: number): string => {
  if (idx === 0) {
    return "col-12 col-sm"
  }
  return "col"
}

export const getColClass = (metric: ApiReportMetric, idx: number): string => {
  if (metric.id === ApiReportMetricsEnum.AVERAGE_CTR_BY_POSITION) {
    return "col-12 col-xl-9"
  }
  if (metric.value && typeof metric.value === "object") {
    return "col-12 col-sm-6"
  } else {
    return getColClassByIdx(idx)
  }
}

export const getItemsForSegmentButton = (filter: ApiReportFilter): SegmentButtonItem[] => {
  return filter.values.map((v) => ({
    label: v.title,
    value: v.value,
    count: v.count
  }))
}

export const convertFiltersToObj = (filters: { [key: string]: SegmentButtonItem }): object => {
  const result: { [key: string]: string } = {}
  Object.entries(filters).forEach(([key, v]) => {
    result[key] = v.value as string
  })
  return result
}

export const getSkeletonColumnNameWidthByKey = (key: string): number | number[] => {
  const map = {
    query_name: 31,
    last_30_days: 65,
    position_child: 31,
    clicks_child: [32, 14],
    ctr_child: 24,
    month: 132,
    best_position_month_child: [32, 14],
    best_position_child: 32,
    best_position_clicks_child: 32,
    best_position_ctr_child: [32, 14],
    best_position_impressions_child: [32, 14]
  }

  return map[key as keyof typeof map]
}

export const getSkeletonCellWidthByKey = (key: string): number => {
  const map = {
    query_name: 101,
    // last_30_days: 65,
    position_child: 10,
    clicks_child: 28,
    ctr_child: 16,
    // month: 132,
    best_position_month_child: 28,
    best_position_child: 11,
    best_position_clicks_child: 16,
    best_position_ctr_child: 28,
    best_position_impressions_child: 28
  }

  return map[key as keyof typeof map]
}
