import { inject, Ref } from "vue"
import { Viewport } from "@/types/common"

export const useViewport = () => {
  const currentViewport = inject("viewport") as Ref<Viewport>

  return {
    viewport: currentViewport
  }
}
