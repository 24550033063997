import { Limit } from "@/types/Limits"
import { ref } from "vue"
import { limitsController } from "@/api/limits/controller"

const limits = ref<Limit>()
export const useLimits = () => {
  const setLimits = (newLimits: Limit) => {
    limits.value = newLimits
  }

  const requestLimits = async () => {
    try {
      const res = await limitsController.findAll()
      if (res) {
        setLimits(res)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    limits,
    requestLimits
  }
}
