import { Controller } from "@/api/decorators"
import BaseController from "@/api/base-controller"
import { ApiLimitsService } from "@/api/limits/service"
import { ApiLimitResponse } from "@/types/Limits"

@Controller(ApiLimitsService)
class LimitsController extends BaseController<ApiLimitsService> {
  public async findAll(): Promise<ApiLimitResponse | undefined | void> {
    return this.service.findAll()
  }
}

export const limitsController = new LimitsController()
