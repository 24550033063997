import { MetricForChart, Viewport } from "@/types/common"
import { EChartsType } from "echarts"
import { Ref } from "vue"

export const formatDataToChart = (data: MetricForChart[]) => {
  const xAxis: string[] = []
  const yAxis: number[] = []

  data.forEach((d) => {
    xAxis.push(`#${d.position}`)
    yAxis.push(parseFloat(d.average_ctr || "0"))
  })

  return [xAxis, yAxis]
}

export const formatDataQueriesToChart = (data: MetricForChart[]) => {
  const xAxis: string[] = []
  const yAxis: number[] = []

  data.forEach((d) => {
    xAxis.push(`#${d.position}`)
    yAxis.push(d.query_count)
  })

  return [xAxis, yAxis]
}

export const getMaxValueForYAxis = (values: number[], interval: number) => {
  return Math.ceil(Math.max(...values) / interval) * interval
}

export const getIntervalForYAxis = (maxValue: number, stepsCount: number) => {
  return Math.floor(maxValue / stepsCount)
}

export const setOptionByVieport = (chart: EChartsType, viewport: Viewport) => {
  const option: any = chart.getOption()
  const newInterval = viewport.isMobile ? 1 : 2
  const newShowMaxLabel = !viewport.isMobile

  if (option.xAxis[0].maxInterval !== newInterval || option.xAxis[0].axisLabel.showMaxLabel !== newShowMaxLabel) {
    option.xAxis[0].maxInterval = newInterval
    option.xAxis[0].axisLabel.showMaxLabel = newShowMaxLabel
    chart.setOption(option)
  }
  chart.resize()
}

export const getOptionForChartByData = (data: MetricForChart[], viewport: Ref<Viewport>) => {
  const [xAxisData, yAxisData] = formatDataToChart(data)
  const [_, yAxisDataQueries] = formatDataQueriesToChart(data)

  const yAxisMaxValue = getMaxValueForYAxis(yAxisData as number[], 10)
  const yAxisQueriesMaxValue = getMaxValueForYAxis(yAxisDataQueries as number[], 10)
  const yAxisQueriesInterval = getIntervalForYAxis(yAxisQueriesMaxValue, 5)
  const yAxisInterval = getIntervalForYAxis(yAxisMaxValue, 5)

  return {
    textStyle: {
      fontFamily: "Rubik, sans-serif"
    },

    grid: {
      top: "16px",
      left: "66px",
      bottom: "32px",
      right: "66px"
    },
    xAxis: {
      type: "value",
      splitLine: {
        show: false
      },
      // data: xAxisData,
      maxInterval: viewport.value.isMobile ? 1 : 2,
      minInterval: 1,
      boundaryGap: false,
      axisLabel: {
        color: (value: string) => {
          if (value === "#4") {
            return "red"
          }
          return "#12304F"
        },
        showMaxLabel: !viewport.value.isMobile,
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 16,
        margin: 16,
        hideOverlap: true,
        formatter: (value: number, index: number) => {
          const currentXValue = data[Math.trunc(value)]
          if (currentXValue) {
            return `#${currentXValue.position}`
          }
          return `#${value}`
        }
      },
      max: "dataMax",
      axisLine: {
        onZero: false,
        lineStyle: {
          width: 0.5,
          color: "#9FB6CD"
        }
      },
      axisTick: {
        length: 8
      },
      axisPointer: {
        show: true,
        id: 123,
        lineStyle: {
          color: "#306FFB"
        }
      }
    },
    yAxis: [
      {
        type: "value",
        interval: yAxisInterval,
        max: yAxisMaxValue,
        offset: 0,
        axisLabel: {
          formatter: "{value}%",
          color: "#718395",
          fontSize: 12,
          lineHeight: 16,
          fontWeight: 100,
          margin: 30
        },
        splitLine: {
          lineStyle: {
            width: 0.5,
            color: "#9FB6CD"
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          length: 26,
          lineStyle: {
            width: 0.5,
            color: "#9FB6CD"
          }
        }
      },
      {
        type: "value",
        interval: yAxisQueriesInterval,
        max: yAxisQueriesMaxValue,
        offset: 0,
        axisLabel: {
          formatter: (value: any) => {
            return value
          },
          color: "#718395",
          fontSize: 12,
          lineHeight: 16,
          fontWeight: 100,
          margin: 30
        },
        axisTick: {
          length: 26,
          lineStyle: {
            width: 0.5,
            color: "#9FB6CD"
          }
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    dataZoom: {
      type: "inside",
      xAxisIndex: [0],
      start: 0,
      end: viewport.value.isMobile ? 70 : 100,
      throttle: 0,
      filterMode: "none",
      zoomLock: true
    },
    series: [
      {
        data: yAxisData.map((d, i) => [i, d]),
        type: "line",
        yAxisIndex: 0,
        color: "#306FFB",
        symbolSize: 5,
        symbol: "circle",
        z: 10,
        lineStyle: {
          width: 1
        }
      },
      {
        data: yAxisDataQueries.map((d, i) => [i, d]),
        type: "line",
        color: "#D2E7FF",
        yAxisIndex: 1,
        symbolSize: 0,
        emphasis: {
          disabled: true
        },
        areaStyle: {
          color: "#D2E7FF"
        },
        lineStyle: {
          width: 1
        }
      }
    ]
  }
}
