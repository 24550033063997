import { Get, Service } from "@/api/decorators"
import { ApiLimitResponse } from "@/types/Limits"

@Service({
  path: "limits"
})
export class ApiLimitsService {
  @Get()
  public async findAll(): Promise<ApiLimitResponse | undefined | void> {}
}
