import { useViewport } from "@/composables/useViewport"
import { computed, nextTick, onMounted, ref } from "vue"
import { TableColumnsType } from "ant-design-vue"
import { vitaminController } from "@/api/vitamin/controller"
import { getGoogleQueriesTableColumns, getTextScoreTableColumns, getUnigramTableColumns } from "@/utils/vitaminazer"
import { ApiVitaminGroupQueryDataItem } from "@/types/Vitamin"

export const useVitaminazerTable = <T>(id: number | null, type: "google-queries" | "unigram" | "text-score") => {
  const { viewport } = useViewport()
  const loading = ref(false)
  const data = ref<T[]>()
  const total = ref<number>()
  const isShowAll = ref<boolean>(false)
  const showedRowCount = ref<number>(20)

  const utils = {
    "google-queries": {
      columns: getGoogleQueriesTableColumns,
      requests: vitaminController.findQueriesData.bind(vitaminController)
    },
    unigram: {
      columns: getUnigramTableColumns,
      requests: vitaminController.findUnigramData.bind(vitaminController)
    },
    "text-score": {
      columns: getTextScoreTableColumns,
      requests: vitaminController.findGroupQueriesData.bind(vitaminController)
    }
  }

  const computedData = computed<T[]>(() => {
    if (type === "text-score") {
      const result: any[] = []

      if (data.value) {
        const _d = isShowAll.value ? data.value : data.value.slice(0, showedRowCount.value)

        ;(_d as unknown as ApiVitaminGroupQueryDataItem[]).forEach((i) => {
          i.queries.forEach((j) => {
            result.push({
              ordinal_number: i.ordinal_number,
              group_name: i.group_name,
              ...j,
              rowSpan: i.queries.length
            })
          })
        })
      }

      return result
    } else {
      if (data.value) {
        if (isShowAll.value) {
          return data.value
        }
        return data.value.slice(0, showedRowCount.value)
      }
      return []
    }
  })

  const computedColumns = computed<TableColumnsType>(utils[type].columns(viewport, computedData))

  const showAll = () => {
    isShowAll.value = !isShowAll.value
  }

  const copyToBuffer = async () => {
    showAll()
    await nextTick(() => {
      const element = document.getElementById(`${id}_${type}`)
      if (element) {
        navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([element.outerHTML], {
              type: "text/html"
            }),
            "text/plain": new Blob([element.innerText], {
              type: "text/plain"
            })
          })
        ])
      }
    })

    isShowAll.value = false
  }

  onMounted(async () => {
    if (id) {
      const res = await utils[type].requests(id, { per_page: 200 })
      if (res) {
        data.value = res.data as unknown as T[]
        total.value = res.meta.total
      }
    }
  })

  return {
    loading,
    data,
    total,
    isShowAll,
    showedRowCount,
    computedColumns,
    computedData,
    showAll,
    copyToBuffer
  }
}
