import { File, Get, PathParam, QueryParams, Serialize, Service } from "@/api/decorators"
import {
  ApiReport,
  ApiReportData,
  ApiReportDataResponse,
  ApiReportGroup,
  ApiReportsQueryParams,
  ApiReportsResponse,
  ApiReportsTypesResponse
} from "@/types/Reports"
import { ApiQueryParams } from "@/api/types"
import { reportSerializer } from "@/utils/reports"

enum Modules {
  TYPES = "/types",
  GROUPS = "/groups"
}

@Service({
  path: "reports"
})
export class ApiReportsService {
  @Get()
  public async findAll(@QueryParams() params?: ApiReportsQueryParams): Promise<ApiReportsResponse | undefined | void> {}

  @Get("/:id")
  public async findOne(@PathParam("id") id: string): Promise<ApiReport | undefined | void> {}

  @Get(Modules.TYPES)
  public async findAllTypes(@QueryParams() params?: ApiReportsQueryParams): Promise<ApiReportsTypesResponse | undefined | void> {}

  @Get(`/:id/data`)
  public async findData(
    @PathParam("id") id: string,
    @QueryParams() params?: ApiQueryParams<ApiReportData & { without_impressions: string }>
  ): Promise<ApiReportDataResponse | undefined | void> {}

  @Get(`${Modules.GROUPS}/:id`)
  @Serialize(reportSerializer)
  public async findOneReportGroup(@PathParam("id") id: string): Promise<ApiReportGroup | undefined | void> {}

  @Get(`${Modules.GROUPS}/:id/file`)
  @File()
  public async findOneReportGroupFile(
    @PathParam("id") id: string,
    @QueryParams() params?: { file_type: "xlsx" | "csv" }
  ): Promise<Blob | undefined | void> {}

  @Get(`${Modules.GROUPS}/order_product/:id`)
  public async findOneByOrderProduct(@PathParam("id") id: string): Promise<ApiReportGroup | undefined | void> {}
}
