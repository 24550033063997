import { computed, ref } from "vue"
import { Site } from "@/types/Sites"
import { NavigationTypes, SiteCardTypes } from "@/types/common"
import { ApiReportDataType, ReportGroup } from "@/types/Reports"
import { useRoute } from "vue-router"
import { ApiOrder, ApiOrderStatus, Order, OrderProduct } from "@/types/Orders"
import { sitesController } from "@/api/sites/controller"
import { ordersController } from "@/api/orders/controller"
import { reportsController } from "@/api/reports/controller"
import { useSelectCountriesModal } from "@/composables/useSelectCountriesModal"

const currentSite = ref<Site>()
const currentReportId = ref<string>()
const currentReportGroup = ref<ReportGroup>()
const currentProducts = ref<OrderProduct[]>()
const lastAudit = ref<Order>()
const reportType = ref<ApiReportDataType>()
const ordersForSite = ref<Order[]>()
export const useCurrentSite = () => {
  const route = useRoute()
  const { selectedCountries } = useSelectCountriesModal()

  const setCurrentSite = (newSite: Site) => {
    currentSite.value = newSite
    return currentSite
  }

  const setCurrentTab = (tab: string) => {
    currentReportId.value = tab
    return currentReportId
  }

  const setCurrentLastAudit = (newAudit: ApiOrder) => {
    lastAudit.value = newAudit
    return lastAudit
  }

  const setCurrentReportGroup = (newReportGroup: ReportGroup) => {
    currentReportGroup.value = newReportGroup
    setCurrentTab(currentReportGroup.value.reports[0].uuid)
    return currentReportGroup
  }

  const setCurrentOrderProducts = (products: OrderProduct[]) => {
    currentProducts.value = products
    return currentProducts
  }

  const setReportDataType = (type: ApiReportDataType | undefined) => {
    if (type) {
      reportType.value = type
    }
  }

  const isGenerated = computed(() => {
    if (lastAudit.value) {
      return lastAudit.value.status === ApiOrderStatus.PROCESSING
    }
    return false
  })

  const isRankKeywordsReport = computed<boolean>(() => {
    return reportType.value === ApiReportDataType.EASY_TO_RANK
  })

  const isThirdGroupReport = computed<boolean>(() => {
    return !reportType.value
  })

  const type = computed<NavigationTypes>(() => {
    if (route.name === "Single Report") {
      return "single-report"
    }

    if (route.name === "All Reports") {
      return "all-reports"
    }

    return undefined
  })

  const reloadSite = async () => {
    if (currentSite.value) {
      const res = await sitesController.findOne(currentSite.value.id)
      if (res) {
        setCurrentSite(res)
        if (res.launch.country) {
          selectedCountries.set(res.id, res.launch.country)
        }
      }
    }
  }

  const updateLastAudit = async (uuid?: string) => {
    if (lastAudit.value || uuid) {
      const lastAuditRes = await ordersController.findOne(uuid || lastAudit.value!.uuid)
      if (lastAuditRes) {
        lastAudit.value = lastAuditRes
        setCurrentLastAudit(lastAuditRes)
      }
    }
  }

  const resetCurrentReportGroup = () => {
    if (currentReportGroup.value) {
      currentReportGroup.value = undefined
    }
  }

  return {
    currentSite,
    setCurrentSite,
    currentReportId,
    setCurrentTab,
    type,
    currentReportGroup,
    setCurrentReportGroup,
    isGenerated,
    reloadSite,
    setCurrentOrderProducts,
    currentProducts,
    lastAudit,
    reportType,
    setReportDataType,
    isRankKeywordsReport,
    // siteType,
    setCurrentLastAudit,
    updateLastAudit,
    ordersForSite,
    isThirdGroupReport,
    resetCurrentReportGroup
  }
}
