import { buildGetRequest, buildPostRequest, buildPutRequest } from "@/api/index"
import { ResponseType } from "axios"
import "reflect-metadata"

interface Metadata {
  path: string
}

type QueryParams = {
  [key: string]: string
}

export type Serializer<T = any> = (obj: T) => T

interface MetadataMethod {
  pathParam: [string, number][]
  responseType: ResponseType
  serializer?: Serializer
  queryParams?: number
  body?: number
}

export function Service(metaData: Metadata) {
  return function (ctor: object) {
    Reflect.defineMetadata("serviceMetadata", metaData, ctor)
  }
}

export function Controller(service: new () => any) {
  return function (ctor: new () => any) {
    Reflect.defineMetadata("controllerMetadata", { service: new service() }, ctor)
    return ctor
  }
}

export function Get(path = "") {
  return (target: object, propertyName: string, descriptor: TypedPropertyDescriptor<any>) => {
    descriptor.value = async (...args: []) => {
      let currentPath = path
      const currentArgs = [...args]
      let queryParams: QueryParams = {}
      const classMethodata = Reflect.getMetadata("serviceMetadata", target.constructor) as Metadata

      if (classMethodata) {
        const methodMetadata: MetadataMethod | undefined = Reflect.getMetadata(propertyName, target)

        if (methodMetadata) {
          const queryIndexInArgs = methodMetadata.queryParams
          if (queryIndexInArgs !== undefined && queryIndexInArgs >= 0) {
            queryParams = currentArgs[queryIndexInArgs] || {}
          }
          if (methodMetadata.pathParam) {
            methodMetadata.pathParam.forEach((p) => {
              currentPath = currentPath.replaceAll(`:${p[0]}`, args[p[1]])
            })
          }
          if (methodMetadata.serializer) {
            const res = await buildGetRequest(classMethodata.path + currentPath, methodMetadata?.responseType)(queryParams)
            if (res) {
              return methodMetadata.serializer(res as object)
            }
          }
        }
        return buildGetRequest(classMethodata.path + currentPath, methodMetadata?.responseType)(queryParams)
      }
    }
  }
}

export function File() {
  return (target: object, propertyName: string, descriptor: TypedPropertyDescriptor<any>) => {
    const metadata: MetadataMethod = Reflect.getMetadata(propertyName, target)
    if (metadata) {
      metadata.responseType = "blob"
    } else {
      Reflect.defineMetadata(propertyName, { responseType: "blob" }, target)
    }
  }
}

export function Serialize(serializer: Serializer) {
  return (target: object, propertyName: string, descriptor: TypedPropertyDescriptor<any>) => {
    const metadata: MetadataMethod = Reflect.getMetadata(propertyName, target)
    if (metadata) {
      metadata.serializer = serializer
    } else {
      Reflect.defineMetadata(propertyName, { serializer }, target)
    }
  }
}

export function getDecoratorBase(type: "post" | "put", path: string) {
  return (target: object, propertyName: string, descriptor: TypedPropertyDescriptor<any>) => {
    descriptor.value = async (...args: []) => {
      let currentPath = path
      let queryString = ""
      let currentArgs = [...args]
      let body = null

      const { path: mainPath } = Reflect.getMetadata("serviceMetadata", target.constructor) as Metadata
      const dynamicParameters: MetadataMethod | undefined = Reflect.getMetadata(propertyName, target)
      if (dynamicParameters) {
        const queryIndexInArgs = dynamicParameters.queryParams
        const bodyIndexInArgs = dynamicParameters.body
        if (queryIndexInArgs !== undefined && queryIndexInArgs >= 0) {
          const queryParams: QueryParams = currentArgs[queryIndexInArgs]
          if (queryParams) {
            queryString = Object.entries(queryParams)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")
          }
        }
        if (bodyIndexInArgs !== undefined && bodyIndexInArgs >= 0) {
          body = currentArgs[bodyIndexInArgs]
        }
        currentArgs = currentArgs.filter(
          (_, idx) => idx !== queryIndexInArgs && !dynamicParameters.pathParam?.some((p) => p[1] === idx)
        )
        if (dynamicParameters.pathParam) {
          dynamicParameters.pathParam.forEach((p) => {
            currentPath = currentPath.replaceAll(`:${p[0]}`, args[p[1]])
          })
        }
      }
      const url = mainPath + currentPath + (queryString ? `?${queryString}` : "")
      if (type === "post") {
        return buildPostRequest<unknown, object>(url)(body || currentArgs[0] || {})
      }
      if (type === "put") {
        return buildPutRequest<unknown, object>(url)(body || currentArgs[0] || {})
      }
    }
  }
}
export function Post(path = "") {
  return getDecoratorBase("post", path)
}

export function Put(path = "") {
  return getDecoratorBase("put", path)
}

export function PathParam(name: string) {
  return (target: object, propertyKey: string | symbol, parameterIndex: number) => {
    const metadata: MetadataMethod = Reflect.getMetadata(propertyKey, target)
    if (metadata) {
      metadata.pathParam ? metadata.pathParam.push([name, parameterIndex]) : (metadata.pathParam = [[name, parameterIndex]])
    } else {
      Reflect.defineMetadata(propertyKey, { pathParam: [[name, parameterIndex]] }, target)
    }
  }
}

export function QueryParams() {
  return (target: object, propertyKey: string | symbol, parameterIndex: number) => {
    const metadata: MetadataMethod = Reflect.getMetadata(propertyKey, target)
    if (metadata) {
      metadata.queryParams = parameterIndex
    } else {
      Reflect.defineMetadata(propertyKey, { queryParams: parameterIndex }, target)
    }
  }
}

export function Body() {
  return (target: object, propertyKey: string | symbol, parameterIndex: number) => {
    const metadata: MetadataMethod = Reflect.getMetadata(propertyKey, target)
    if (metadata) {
      metadata.body = parameterIndex
    } else {
      Reflect.defineMetadata(propertyKey, { body: parameterIndex }, target)
    }
  }
}
