import { Get, QueryParams, Service } from "@/api/decorators"
import { ApiPaymentsQueryParams, ApiPaymentsResponse, ApiPaymentsUrl, ApiPaymentsUrlResponse } from "@/types/Payments"
import { ApiServices } from "@/api"

@Service({
  path: "paypro-global"
})
class ApiPayProGlobalService {
  @Get("/url")
  public async getPaymentUrl(@QueryParams() payload: ApiPaymentsUrl): Promise<ApiPaymentsUrlResponse | undefined | void> {}
}

export const payProGlobalService = new ApiPayProGlobalService()
