import { ApiAgreementsService } from "@/api/agreements/service"
import { Controller } from "@/api/decorators"
import BaseController from "@/api/base-controller"
import {
  ApiAgreement,
  ApiAgreementsPostAgreementAcceptPayload,
  ApiAgreementsQueryParams,
  ApiAgreementsResponse
} from "@/types/Agreements"

@Controller(ApiAgreementsService)
class AgreementsController extends BaseController<ApiAgreementsService> {
  public async findAll(params?: ApiAgreementsQueryParams): Promise<ApiAgreementsResponse | undefined | void> {
    return this.service.findAll(params)
  }

  public async findOne(id: string): Promise<ApiAgreement | undefined | void> {
    return this.service.findOne(id)
  }

  public async findAllUnaccepted(params?: ApiAgreementsQueryParams): Promise<ApiAgreementsResponse | undefined | void> {
    return this.service.findAllUnaccepted(params)
  }

  public async accept(payload: ApiAgreementsPostAgreementAcceptPayload): Promise<ApiAgreementsResponse | undefined | void> {
    return this.service.accept(payload)
  }
}

export const agreementsController = new AgreementsController()
