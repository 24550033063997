import { ApiQueryParams, ApiResponse } from "@/api/types"
import { ApiOrder } from "@/types/Orders"

export type ApiPayment = {
  id: string
  order: ApiPaymentOrder
  pg_order_status: ApiPaymentOrderStatus
  pg_order_item_total_amount: string
  pg_invoice_link: string
  pg_order_id: string
  pg_order_item_name: string
  created_at: Date | string | null
}

export type ApiPaymentOrder = ApiOrder
export enum ApiPaymentOrderStatus {
  PROCESSING = "Waiting",
  PAID = "Processed",
  DECLINED = "Canceled",
  REFUND = "Refunded"
}

export type ApiPaymentsQueryParams = ApiQueryParams<ApiPayment>
export type ApiPaymentsUrl = {
  order_uuid: string
}

export type Payment = ApiPayment
export type ApiPaymentsSerializer = (items: ApiPayment[] | undefined) => ApiPayment[] | undefined
export type ApiPaymentSerializer = (item: ApiPayment | undefined) => ApiPayment | undefined
export type ApiPaymentsResponse = ApiResponse<ApiPayment[]>
export type ApiPaymentsUrlResponse = {
  url: string
}
