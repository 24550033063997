import { DefineComponent, getCurrentInstance, h } from "vue"
import { ModalFunc } from "ant-design-vue/lib/modal/Modal"
import { useViewport } from "@/composables/useViewport"
import { Modal } from "ant-design-vue"

export const useModal = (
  modalComponent: DefineComponent,
  options: {
    className: string
    width: string
  },
  props?: { onClose?: () => Promise<void> | void; [key: string]: any }
) => {
  let modal: ReturnType<ModalFunc>
  const appContext = getCurrentInstance()?.appContext
  const { viewport } = useViewport()

  const resizeListener = () => {
    modal.update({
      width: viewport.value.isMobile ? "100%" : options.width
    })
  }

  const open = () => {
    const _props = {
      ...props,
      onClose: async () => {
        if (props && props.onClose) {
          await props.onClose()
          close()
        } else {
          close()
        }
      }
    }

    modal = Modal.confirm({
      closable: props && "closable" in props ? props.closable : true,
      maskClosable: props && "maskClosable" in props ? props.maskClosable : true,
      mask: props && "mask" in props ? props.mask : true,
      zIndex: 10,
      class: `${options.className}-container`,
      width: viewport.value.isMobile ? "100%" : options.width,
      wrapClassName: `${options.className}-wrapper`,
      modalRender: () => h("div", { class: "ant-modal-content" }, h(modalComponent, _props)),
      appContext
    })

    window.addEventListener("resize", resizeListener)
  }

  const close = () => {
    if (modal) {
      modal.destroy()
    }
    window.removeEventListener("resize", resizeListener)
  }

  return {
    open,
    close
  }
}
