export const formatNumber = (value: string | number): string => {
  if (!value) return ""
  if (typeof value === "number") {
    return value.toLocaleString().replaceAll(",", ".")
  } else {
    if (value.includes("%")) {
      return value
    }
    const num = value.includes(".") ? parseFloat(value) : parseInt(value)
    if (num) {
      return num.toLocaleString().replaceAll(",", ".")
    } else {
      return value
    }
  }
}
