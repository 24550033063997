import { Controller } from "@/api/decorators"
import BaseController from "@/api/base-controller"
import { ApiOrderService } from "@/api/orders/service"
import {
  ApiOrder,
  ApiOrdersLaunchOrderPayload,
  ApiOrdersPostNewOrderPayload,
  ApiOrdersQueryParams,
  ApiOrdersResponse
} from "@/types/Orders"
import { ApiQueryParams } from "@/api/types"

@Controller(ApiOrderService)
class OrderController extends BaseController<ApiOrderService> {
  public async findAll(params?: ApiOrdersQueryParams): Promise<ApiOrdersResponse | undefined | void> {
    return this.service.findAll(params)
  }

  public async findOne(id: string | number): Promise<ApiOrder | undefined | void> {
    return this.service.findOne(`${id}`)
  }

  public async createOrder(payload: ApiOrdersPostNewOrderPayload): Promise<ApiOrder | undefined | void> {
    return this.service.createOrder(payload)
  }

  public async launchOrder(payload: ApiOrdersLaunchOrderPayload): Promise<{ message: string } | undefined | void> {
    return this.service.launchOrder(payload)
  }

  public async findOrderBySiteUrl({
    site_url,
    ...params
  }: ApiQueryParams<{ created_at: string }> & { site_url: string }): Promise<ApiOrdersResponse | undefined | void> {
    return this.service.findAll({ "filter[site_url]": site_url, "filter[status]": ["completed", "processing"], ...params })
  }
}

export const ordersController = new OrderController()
