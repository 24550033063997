import { PaymentStatusBadgeColors } from "@/consts/payments"
import { ApiPaymentOrderStatus, Payment } from "@/types/Payments"

export const getPaymentBadgeColorByPaymentStatus = (status: ApiPaymentOrderStatus): string => {
  return PaymentStatusBadgeColors[status]
}

export const isPaidPayment = (payment: Payment): boolean => {
  return payment.pg_order_status === ApiPaymentOrderStatus.PAID
}
export const isProcessingPayment = (payment: Payment): boolean => {
  return payment.pg_order_status === ApiPaymentOrderStatus.PROCESSING
}
export const isDeclinedPayment = (payment: Payment): boolean => {
  return payment.pg_order_status === ApiPaymentOrderStatus.DECLINED
}
export const isRefundPayment = (payment: Payment): boolean => {
  return payment.pg_order_status === ApiPaymentOrderStatus.REFUND
}

export const isProcessingOrDeclinedPayment = (payment: Payment) => {
  return isProcessingPayment(payment) || isDeclinedPayment(payment)
}
