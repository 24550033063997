<template>
  <svg
    class="content-vitaminaizer-icon"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="8" fill="#5CC971" fill-opacity="0.12" />
    <g clip-path="url(#clip0_7716_40298)">
      <path
        d="M23.7188 9.25L22.5 6L21.2812 9.25L19 10L21.2812 10.75L22.5 14L23.7188 10.75L26 10L23.7188 9.25ZM21.7188 21.25L20.5 18L19.2812 21.25L17 22L19.2812 22.75L20.5 26L21.7188 22.75L24 22L21.7188 21.25ZM14.4688 13.5L12.5 8L10.5312 13.5L6 15L10.5312 16.5L12.5 22L14.4688 16.5L19 15L14.4688 13.5Z"
        fill="#5CC971"
      />
    </g>
    <defs>
      <clipPath id="clip0_7716_40298">
        <rect width="20" height="20" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup lang="ts"></script>

<style scoped lang="scss">
@import "@/assets/scss/mixins";
.content-vitaminaizer-icon {
  transition: all 0.3s ease-in-out;
  @include lg() {
    width: 24px;
    height: 24px;
  }
}
</style>
