export const metaTitleDirective = {
  created(el: any, binding: any) {
    if (binding.value && document.title !== binding.value) {
      document.title = binding.value
    }
  },
  // called right before the element is inserted into the DOM.
  beforeMount(el: any, binding: any) {
    if (binding.value && document.title !== binding.value) {
      document.title = binding.value
    }
  },
  mounted(el: any, binding: any) {
    if (binding.value && document.title !== binding.value) {
      document.title = binding.value
    }
  },
  beforeUpdate(el: any, binding: any) {
    if (binding.value && document.title !== binding.value) {
      document.title = binding.value
    }
  },
  updated(el: any, binding: any) {
    if (binding.value && document.title !== binding.value) {
      document.title = binding.value
    }
  }
}
