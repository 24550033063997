import { Get, Post, Serialize, Service } from "@/api/decorators"
import { ApiServices } from "@/api"
import {
  ApiActivateDiscountPayload,
  ApiAddDiscountPayload,
  ApiAddDiscountResponse,
  ApiDiscountsResponse,
  ApiUseDiscountResponse
} from "@/types/Discounts"

enum Modules {
  CLIENTS = "/clients",
  CLIENTS_COUNT = "/clients/count",
  ORDER_ACTIVATE = "/clients/orders/activate"
}

@Service({
  path: "discounts"
})
export class ApiDiscountService {
  @Get(Modules.CLIENTS_COUNT)
  public async findAll(): Promise<ApiDiscountsResponse | undefined | void> {}

  @Post(Modules.CLIENTS)
  public async activateDiscount(payload: ApiAddDiscountPayload): Promise<ApiAddDiscountResponse | undefined | void> {}

  @Post(Modules.ORDER_ACTIVATE)
  public async useDiscountToOrder(payload: ApiActivateDiscountPayload): Promise<ApiUseDiscountResponse | undefined | void> {}
}
