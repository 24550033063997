import { computed, ref, watch } from "vue"
import { AxiosError } from "axios/index"
import { useFetchGoogleAuth } from "@/api/auth"
import router, { Routes } from "@/router"
import { ApiAuthGoogleCallbackResponse } from "@/types/Auth"

const error = ref<AxiosError<ApiAuthGoogleCallbackResponse>>()
export const useErrorException = () => {
  const isUnauthenticated = computed<boolean>(() => {
    if (error.value) {
      return (
        error.value.response?.status === 401 &&
        error.value?.response.data.message === "Unauthenticated." &&
        window.location.pathname !== Routes.GOOGLE
      )
    }
    return false
  })

  watch(error, async () => {
    if (error.value) {
      if (isUnauthenticated.value) {
        const googleAuthLink = await useFetchGoogleAuth()
        if (googleAuthLink) {
          window.location.replace(googleAuthLink.target_url)
        }
      } else {
        // TODO: убрать
        console.log(error)
        if (error.value && error.value.response && error.value.response.data.code) {
          if (error.value.response.data.message) {
            await router.push(
              `${Routes.SIGN_IN}?code=${error.value.response.data.code}&message=${error.value.response.data.message}`
            )
          } else {
            await router.push(`${Routes.SIGN_IN}?code=${error.value.response.data.code}`)
          }
        } else {
          await router.push(`${Routes.SIGN_IN}?code=${error.value?.response?.data.message}`)
        }
      }
    }
  })
  return {
    error
  }
}
