import { ApiClient, Client } from "@/types/Client"
import { computed, getCurrentInstance, ref } from "vue"
import Pusher from "pusher-js"
import { notify } from "@/components/UI/Notification"
import { ApiNotificationType, WSNotification } from "@/types/Notifications"
import { useNotificaitonDrawer } from "@/composables/useNotificaitonDrawer"
import { clientController } from "@/api/client/controller"
import { useSites } from "@/composables/useSites"

const currentClient = ref<Client>()

export const useCurrentClient = () => {
  const { isShow, currentPage, requestNotifications } = useNotificaitonDrawer()
  const app = getCurrentInstance()
  const { reloadSites } = useSites()

  const setCurrentClient = (newClient: Client) => {
    currentClient.value = newClient
    return currentClient
  }

  const subscribeNotifications = (client: Client) => {
    const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: "eu"
    })

    const channel = pusher.subscribe(`${client.id}.notifications`)
    channel.bind("NewNotificationsBroadcastEvent", async (notification: WSNotification) => {
      // notify.audio()
      if ("message" in notification) {
        notify.open(
          {
            id: `${new Date().getTime()}`,
            type: (notification.type as ApiNotificationType) || ApiNotificationType.UNKNOWN,
            data: {
              message: notification.message,
              site_id: notification.site_id,
              site_url: notification.site_url
            },
            read_at: null,
            created_at: new Date(),
            updated_at: new Date()
          },
          app?.appContext || null
        )
      } else {
        notify.open(notification.data, app?.appContext || null)
      }
      if (isShow.value) {
        currentPage.value = 1
      }
      await requestNotifications()
    })
  }

  let timeout: number
  const listenSitesUpdated = async () => {
    if (currentClient.value && currentClient.value.sites_are_updated) {
      const clientRes = await clientController.getProfile()
      if (clientRes && !clientRes.sites_are_updated) {
        setCurrentClient(clientRes)
        clearInterval(timeout)
        await reloadSites()
      } else {
        timeout = setTimeout(async () => {
          await reloadCurrentClient()
          await listenSitesUpdated()
        }, 1000)
      }
    }
  }

  const reloadCurrentClient = async (cb?: (clientResponse: ApiClient) => Promise<void> | void) => {
    const clientRes = await clientController.getProfile()
    if (clientRes) {
      setCurrentClient(clientRes)
      cb && (await cb(clientRes))
    }
  }

  const requestCurrentClient = async () => {
    // const clientRes = await clientController.getProfile()
    // if (clientRes) {
    //   setCurrentClient(clientRes)
    //   subscribeNotifications(clientRes)
    // }
    await reloadCurrentClient(subscribeNotifications)
  }

  const isHasAccessToGSC = computed<boolean>(() => {
    if (currentClient.value) {
      return currentClient.value?.has_access_to_gsc
    }
    return false
  })

  return {
    currentClient: currentClient,
    setCurrentClient,
    requestCurrentClient,
    listenSitesUpdated,
    isHasAccessToGSC
  }
}
