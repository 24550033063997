import { onMounted, reactive, ref } from "vue"
import { vitaminController } from "@/api/vitamin/controller"
import { ApiVitaminRecommendationData } from "@/types/Vitamin"
import { useCurrentClient } from "@/composables/useCurrentClient"

const selectedUrl = ref<string>()
const recommendation = ref<ApiVitaminRecommendationData>()

export const useVitaminazerRecommendation = () => {
  const resetRecommendation = () => {
    selectedUrl.value = undefined
    recommendation.value = undefined
  }

  const requestRecommendation = async () => {
    const res = await vitaminController.findRecommendation()
    if (res && res.data) {
      recommendation.value = res.data
    }
  }

  return {
    selectedUrl,
    recommendation,
    requestRecommendation,
    resetRecommendation
  }
}
