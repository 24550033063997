import { ApiReportsService } from "@/api/reports/service"
import { Controller } from "@/api/decorators"
import BaseController from "@/api/base-controller"
import {
  ApiReport,
  ApiReportData,
  ApiReportDataResponse,
  ApiReportGroup,
  ApiReportsQueryParams,
  ApiReportsResponse
} from "@/types/Reports"
import { ApiQueryParams } from "@/api/types"

@Controller(ApiReportsService)
class ReportsController extends BaseController<ApiReportsService> {
  public async findAll(params?: ApiReportsQueryParams): Promise<ApiReportsResponse | undefined | void> {
    return this.service.findAll(params)
  }

  public async findOne(id: string): Promise<ApiReport | undefined | void> {
    return this.service.findOne(id)
  }

  public async findData(
    id: string,
    params?: ApiQueryParams<ApiReportData & { without_impressions: string }>
  ): Promise<ApiReportDataResponse | undefined | void> {
    return this.service.findData(id, params)
  }

  public async findOneReportGroup(id: string): Promise<ApiReportGroup | undefined | void> {
    return this.service.findOneReportGroup(id)
  }

  public async findOneReportGroupFile(id: string, params?: { file_type: "xlsx" | "csv" }): Promise<Blob | undefined | void> {
    return this.service.findOneReportGroupFile(id, params)
  }

  public async findOneByOrderProduct(id: string): Promise<ApiReportGroup | undefined | void> {
    return this.service.findOneByOrderProduct(id)
  }
}

export const reportsController = new ReportsController()
