import { ApiQueryParams, ApiResponse } from "@/api/types"

export type ApiNotification = {
  id: string
  type: ApiNotificationType
  data: ApiNotificationData
  read_at: Date | string | null
  created_at: Date | string | null
  updated_at: Date | string | null
}

export enum ApiNotificationType {
  SITE_CREATE = "SiteCreate",
  WELCOME = "Welcome",
  WELCOME_NOTIFICATION = "WelcomeNotification",
  UNKNOWN = "Unknown",
  ORDER_COMPLETED = "OrderCompleted",
  FETCH_SITE_FAILED = "FetchSiteFailed",
  PAYMENT = "Payment",
  PAYMENT_FAILED = "PaymentFailed",
  ORDER_FAILED = "OrderFailed",
  VITAMIN_COMPLETED = "VitaminCompleted",
  VITAMIN_FAILED = "VitaminFailed"
}

export type ApiNotificationData = Partial<{
  site_id: number | string
  [key: string]: unknown
  site_url?: string
}>

export type ApiNotificationsReadNotificaitionsPayload = {
  notifications_ids: string[]
}
export type Notification = ApiNotification
export type ApiNotificationsQueryParams = ApiQueryParams<ApiNotification & { site_url: string; sort: string }>
export type ApiNotificationsResponse = ApiResponse<ApiNotification[]>
export type ApiNotificationsUnsubscribeResponse = {
  message: string
}
export type WSNotification = {
  channel: string
  data: ApiNotification
  event: string
  type?: ApiNotificationType
  message: string
  site_id?: string
  site_url?: string
}
