import dayjs from "dayjs"
import { ApiReport, ApiReportMetric, ReportData, ReportGroup } from "@/types/Reports"
import { Site } from "@/types/Sites"
import { formatNumber } from "@/utils/common"
import { Serializer } from "@/api/decorators"

export const downloadReportFile = (url: string) => {
  // const url = window.URL.createObjectURL(res)
  const a = document.createElement("a")
  a.style.display = "none"
  a.href = process.env.VUE_APP_CORE_API_URL + url
  // const siteName = site.name.replaceAll("https://", "").replaceAll("/", "")
  // const reportName = currentReportGroup.name.replaceAll(" ", "_")
  // const date = dayjs(currentReportGroup.created_at).format("DD-MM-YYYY")
  // a.download = `${siteName}_${reportName}_${date}.${fileType}`
  document.body.appendChild(a)
  a.click()
  // window.URL.revokeObjectURL(url)
}

export const reportSerializer: Serializer<ApiReport> = (report: ApiReport): ApiReport => {
  const copyReport: ApiReport = JSON.parse(JSON.stringify(report))

  copyReport.metrics = copyReport.metrics.map((m) => {
    return {
      ...m,
      value: typeof m.value === "string" ? formatNumber(m.value) : m.value
    }
  })

  return copyReport
}

export const isMetricValueObject = (metric: ApiReportMetric): boolean => {
  if (metric.value && typeof metric.value === "object") {
    return true
  }
  return false
}

export const isMetricValueArray = (metric: ApiReportMetric): boolean => {
  return Array.isArray(metric.value)
}

export const getReportDataMockForSkeleton = (): ReportData[] => {
  return Array.from({ length: 50 }, () => ({
    position: "",
    clicks: 0,
    ctr: "",
    impressions: 0,
    lost_traffic: "",
    position_diff: "",
    best_position: "",
    best_position_clicks: 0,
    best_position_impressions: 0,
    best_position_ctr: "",
    query_name: "",
    query_link: "",
    clicks_in_year: 0,
    best_position_start_date: null,
    best_position_end_date: null,
    missed_traffic: "",
    key_rel: 0,
    difference_ctr: "",
    average_ctr: ""
  }))
}
