import { Get, PathParam, Post, QueryParams, Service } from "@/api/decorators"
import { ApiServices } from "@/api"
import {
  ApiAgreement,
  ApiAgreementsPostAgreementAcceptPayload,
  ApiAgreementsQueryParams,
  ApiAgreementsResponse
} from "@/types/Agreements"

enum Modules {
  ACCEPT = "/accept",
  UNACCEPTED = "/unaccepted"
}

@Service({
  path: "agreements"
})
export class ApiAgreementsService {
  @Get()
  public async findAll(@QueryParams() params?: ApiAgreementsQueryParams): Promise<ApiAgreementsResponse | undefined | void> {}

  @Get("/:id")
  public async findOne(@PathParam("id") id: string): Promise<ApiAgreement | undefined | void> {}

  @Get(Modules.UNACCEPTED)
  public async findAllUnaccepted(
    @QueryParams() params?: ApiAgreementsQueryParams
  ): Promise<ApiAgreementsResponse | undefined | void> {}

  @Post(Modules.ACCEPT)
  public async accept(payload: ApiAgreementsPostAgreementAcceptPayload): Promise<ApiAgreementsResponse | undefined | void> {}
}
