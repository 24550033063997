import { Viewport } from "@/types/common"
import { Ref } from "vue"
import { TableColumnsType } from "ant-design-vue"
import { ApiVitaminGroupQueryDataItem } from "@/types/Vitamin"

export const getGoogleQueriesTableColumns = (viewport: Ref<Viewport>) => (): TableColumnsType => {
  return [
    {
      title: "№",
      dataIndex: "ordinal_number",
      key: "ordinal_number",
      fixed: "left",
      width: viewport.value.isMobile ? 54 : 72,
      sorter: false
    },
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      fixed: "left",
      width: viewport.value.isMobile ? 180 : 354,
      sorter: false
    },
    {
      title: "Missing words",
      dataIndex: "missing_words",
      key: "missing_words",
      width: viewport.value.isMobile ? 180 : 180,
      sorter: false
    },
    {
      title: "Query stats for the previous 90 days",
      key: "previous_90_days",
      children: [
        {
          title: "Clicks",
          dataIndex: "clicks",
          key: "clicks_child",
          width: 140,
          sorter: false
        },
        {
          title: "Impressions",
          dataIndex: "impressions",
          key: "impressions_child",
          width: 140,
          sorter: false
        },
        {
          title: "CTR %",
          dataIndex: "ctr",
          key: "ctr_child",
          width: 100,
          sorter: false
        },

        {
          title: "Position",
          dataIndex: "position",
          key: "position_child",
          width: 100,
          sorter: false
        }
      ]
    },
    { title: "TextScore\nby query", dataIndex: "textScoreByQuery", key: "textScoreByQuery", width: 120, sorter: false }
  ]
}

export const getUnigramTableColumns = (viewport: Ref<Viewport>) => (): TableColumnsType => {
  return [
    {
      title: "№",
      dataIndex: "ordinal_number",
      key: "ordinal_number",
      fixed: "left",
      width: viewport.value.isMobile ? 51 : 51,
      sorter: false
    },
    {
      title: "Unigram",
      dataIndex: "unigram",
      key: "unigram",
      fixed: "left",
      width: viewport.value.isMobile ? 147 : 147,
      sorter: false
    },
    {
      title: "Number\nof queries",
      dataIndex: "number_queries",
      key: "number_queries",
      width: viewport.value.isMobile ? 81 : 81,
      sorter: false
    },
    {
      title: "Total number of impressions",
      dataIndex: "total_number_impressions",
      key: "total_number_impressions",
      width: 108,
      sorter: false
    }
  ]
}

export const getTextScoreTableColumns = (viewport: Ref<Viewport>, data: Ref<any[] | undefined>) => (): TableColumnsType => {
  if (data.value) {
    const ranges = new Map()

    data.value.forEach((item: any, index) => {
      if (!ranges.has(item.group_name)) {
        ranges.set(item.group_name, [index, index])
      } else {
        ranges.get(item.group_name).pop()
        ranges.get(item.group_name).push(index)
        // result.set(item.query, [...result.get(item.query).push(index)])
      }
    })

    const customCell = (item: ApiVitaminGroupQueryDataItem, index: any) => {
      const currentGroup = ranges.get(item.group_name)
      if (currentGroup) {
        const start = currentGroup[0]
        const end = currentGroup[1]
        const rowSpan = end - start + 1
        if (index === start) {
          if (data.value && data.value[data.value.length - 1].ordinal_number === item.ordinal_number) {
            return { rowSpan, style: { borderBottom: "none !important" } }
          }
          return { rowSpan }
        } else if (index! <= end) {
          return { rowSpan: 0 }
        }
      }
      return {}
    }

    return [
      {
        title: "№",
        dataIndex: "ordinal_number",
        key: "ordinal_number",
        fixed: "left",
        width: viewport.value.isMobile ? 55 : 55,
        sorter: false,
        customCell
      },
      {
        title: "Group",
        dataIndex: "group_name",
        key: "group_name",
        fixed: "left",
        width: viewport.value.isMobile ? 140 : 140,
        sorter: false,
        customCell
      },
      {
        title: "Query",
        dataIndex: "query",
        key: "query",
        fixed: viewport.value.isMobile ? false : "left",
        width: viewport.value.isMobile ? 232 : 232,
        sorter: false
      },
      {
        title: "Missing words",
        dataIndex: "missing_words",
        key: "missing_words",
        width: viewport.value.isMobile ? 140 : 140,
        sorter: false
      },
      {
        title: "Impressions",
        dataIndex: "impressions",
        key: "impressions",
        width: 92,
        sorter: false
      },
      { title: "TextScore\nby query", dataIndex: "textScoreByQuery", key: "textScoreByQuery", width: 120, sorter: false }
    ]
  } else {
    return []
  }
}
