import { ref } from "vue"
import { ApiOrder } from "@/types/Orders"

const currentLastAudit = ref<ApiOrder>()

export const useCurrentLastAudit = () => {
  const setCurrentLastAudit = (newAudit: ApiOrder) => {
    currentLastAudit.value = newAudit
    return currentLastAudit
  }

  return {
    currentLastAudit,
    setCurrentLastAudit
  }
}
