export type NavigationTypes = "single-site" | "single-report" | "all-reports" | undefined

export type SegmentButtonItem = {
  value: string
  label: string
  count?: number
}

export type Viewport = {
  viewport: "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
  isMobile: boolean
}

export enum SiteCardTypes {
  FIRST_AUDIT = "first-audit",
  NEW_AUDIT = "new-audit",
  DEFAULT_WITH_AUDIT = "default-with-audit",
  PROCESSING = "processing",
  PROCESSING_WITH_AUDIT = "processing-with-audit",
  NEW_PROJECT = "new-project",
  NOT_ACCESS = "not-access",
  SKELETON = "skeleton",
  FAILED = "failed",
  COMPLETED_TODAY = "completed-today",
  MAX_ALLOWED = "max-allowed",
  MIN_ALLOWED = "min-allowed",
  UPDATING = "updating"
}

export type SortItem = {
  id: string | number
  label: string
}

export type SkeletonListItemSize = Partial<{
  sm: number
  md: number
  lg: number
  xl: number
  xxl: number
}>

export type SkeletonListItem = {
  w: number | SkeletonListItemSize
  h: number | SkeletonListItemSize
  round: number
}

export type MetricForChart = {
  average_ctr: string
  position: string
  query_count: number
}
