import { ApiQueryParams, ApiResponse } from "@/api/types"
import { ApiReport } from "@/types/Reports"

export enum ApiVitaminStatusNames {
  AVAILABLE = "available",
  PROCESSING = "processing",
  UNAVAILABLE = "unavailable"
}

export type ApiVitaminStatus = {
  status: ApiVitaminStatusNames
  message: string
  limit: {
    spentCount: number
    availableCount: number
    resetDate: string
  }
}

export type ApiVitaminGeneratePayload = {
  url: string
  siteId?: number
}

export type ApiVitaminGenerateResponse =
  | {
      message: string
      errors?: {
        url: string[]
      }
    }
  | ApiVitaminLastGeneration

export enum ApiVitaminLastGenerationStatus {
  PROCESSING = "processing",
  COMPLETED = "completed",
  FAILED = "failed",
  SUCCEEDED = "succeeded"
}

export type ApiVitaminError = null | { message: string; description: string }

export type ApiVitaminLastGeneration = {
  id: number
  url: string
  status: ApiVitaminLastGenerationStatus
  created_at: string
  error: ApiVitaminError
} | null

export type ApiVitaminPageTextScore = {
  countTextScore: number
  countSymbols: string
  countWords: string
}

export type ApiVitaminPageText = {
  textScore: number
  countSymbols: string
  countWords: string
  text: string
}

export type ApiVitaminGenerationData = {
  pageTextScore: ApiVitaminPageTextScore
  texts: ApiVitaminPageText[]
}

export type ApiVitaminGenerationDataResponse = {
  id: number
  url: string
  status: ApiVitaminLastGenerationStatus
  created_at: string
  data: ApiVitaminGenerationData | null
  error: ApiVitaminError
}

export type ApiVitaminQueriesDataItem = {
  ordinal_number: number
  query: string
  missing_words: string
  clicks: string
  impressions: string
  ctr: string
  position: string
  textScoreByQuery: number
}

export type ApiVitaminUnigramDataItem = {
  ordinal_number: number
  unigram: string
  number_queries: string
  total_number_impressions: string
}

export type ApiVitaminGroupQueryDataItem = {
  ordinal_number: number
  group_name: string
  queries: {
    query: string
    missing_words: string
    impressions: string
    textScoreByQuery: number
  }[]
}

export type ApiVitaminRecommendationData = {
  id: number
  site: {
    id: number
    url: string
    name: string
  }
  pages: string[]
}

export type ApiVitaminRecommendationResponse = {
  data: ApiVitaminRecommendationData | null
}

export type ApiVitaminQueriesDataResponse = ApiResponse<ApiVitaminQueriesDataItem[]>
export type ApiVitaminUnigramDataResponse = ApiResponse<ApiVitaminUnigramDataItem[]>
export type ApiVitaminGroupQueriesDataResponse = ApiResponse<ApiVitaminGroupQueryDataItem[]>

export type ApiVitaminDataPayload = ApiQueryParams
