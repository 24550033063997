import { computed } from "vue"
import { Routes, RoutesName } from "@/router"
import { useRoute } from "vue-router"

export const useRoutePages = () => {
  const route = useRoute()

  const isMyProjectPage = computed(() => {
    return route.path === Routes.SITES
  })

  const isAllReportsPage = computed(() => {
    return route.name === RoutesName.ALL_REPORTS
  })

  return {
    isMyProjectPage,
    isAllReportsPage
  }
}
