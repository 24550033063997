<template>
  <p class="mb-3">
    How much additional traffic could there have been in the last 30 days if the site for this request was in the top three with
    an <i>average*</i> CTR.
  </p>
  <p class="mb-3">The value is rounded to a whole number.</p>
  <p class="mb-3">* Here, the site's CTR for all queries for which it is already ranked in the top three is considered average</p>
  <span class="fs-6 lh-8 text-start">Learn more in the tutorial </span> <br />
  <a
    href="https://buddler.com/tutorials/metrics-for-audit-and-report-preview"
    target="_blank"
    class="fw-medium lh-8 fs-6 text-decoration-underline"
  >
    Metrics for Audit and Report <br />
    Preview
  </a>
</template>
<script setup lang="ts"></script>

<style scoped lang="scss"></style>
