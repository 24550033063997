import { ApiQueryParams, ApiResponse } from "@/api/types"

export type ApiReport = {
  uuid: string
  name: string
  client: ApiReportClient
  site: ApiReportSite
  status: ApiReportStatus
  type: ApiReportType
  created_at: Date | string | null
  deleted_at: Date | string | null
  settings: ApiReportSetting[]
  metrics: ApiReportMetric[]
  filters: ApiReportFilter[]
}

export type ApiReportFilter = {
  type: string
  key: string
  required: boolean
  values: ApiReportFilterValue[]
}

export type ApiReportFilterValue = {
  title: string
  value: string
  count: number
}

export type ApiReportGroup = {
  uuid: string
  name: string
  site: ApiReportSite
  created_at: Date | string | null
  reports: ApiReportInGroup[]
  metrics: ApiReportMetric[]
  order: {
    uuid: string
  }
  country: ApiReportGroupCountry
}

export type ApiReportGroupCountry = {
  id: number
  code: string
  name: string
}

export type ApiReportInGroup = {
  uuid: string
  name: string
  filters: ApiReportFilter[]
}

export type ApiReportMetric = {
  id: ApiReportMetricsEnum
  key?: ApiReportMetricsEnum
  name: string
  value: any
  value_suffix?: string | null | undefined
  description?: string | null | undefined
}
// export enum ApiReportMetricsEnum {
//   POTENTIAL_TRAFFIC = "report_group_potential_traffic",
//   POTENTIAL_SECOND_TRAFFIC = "report_two_group_potential_traffic",
//   ORDER_POTENTIAL_TRAFFIC = "order_potential_traffic",
//   REPORT_POTENTIAL_TRAFFIC = 4,
//   REPORT_SECOND_POTENTIAL_TRAFFIC = "report_second_potential_traffic",
//   MISSED_TRAFFIC = "report_group_missed_traffic",
//   ORDER_MISSED_TRAFFIC = "order_missed_traffic",
//   REPORT_MISSED_TRAFFIC = "report_missed_traffic",
//   REPORT_SECOND_MISSED_TRAFFIC = "report_second_missed_traffic",
//   REPORT_SECOND_GROUP_MISSED_TRAFFIC = "report_second_group_missed_traffic",
//   REQUEST_IN_REPORT = "report_group_requests_count",
//   ORDER_REQUEST_IN_REPORT = "order_requests_count",
//   REPORT_REQUEST_IN_REPORT = "report_requests_count",
//   REPORT_SINGLE_REQUEST_IN_REPORT = "report_single_requests_count",
//   REPORT_QUERY_IN_REPORT = "report_query_count",
//   REPORT_GROUP_QUERY_IN_REPORT = "report_group_query_count",
//   ORDER_CLICKS_COUNT = "order_sum_all_clicks_in_last_days_count",
//   WEIGHTED_KEY_REL = "report_group_weighted_key_rel",
//   WEIGHTED_KEY_REL_REPORT = "report_weighted_key_rel"
// }

export enum ApiReportMetricsEnum {
  POTENTIAL_TRAFFIC = 11,
  POTENTIAL_SECOND_TRAFFIC = 14,
  ORDER_POTENTIAL_TRAFFIC = 18,
  REPORT_POTENTIAL_TRAFFIC = 3,
  REPORT_SECOND_POTENTIAL_TRAFFIC = 5,
  MISSED_TRAFFIC = 10,
  ORDER_MISSED_TRAFFIC = 17,
  REPORT_MISSED_TRAFFIC = 4,
  REPORT_SECOND_MISSED_TRAFFIC = 2,
  REPORT_SECOND_GROUP_MISSED_TRAFFIC = 9,
  REQUEST_IN_REPORT = 8,
  ORDER_REQUEST_IN_REPORT = 16,
  ORDER_QUERY_IN_REPORT = 17,
  REPORT_REQUEST_IN_REPORT = 1,
  REPORT_SINGLE_REQUEST_IN_REPORT = 19,
  REPORT_QUERY_IN_REPORT = 6,
  REPORT_GROUP_QUERY_IN_REPORT = 12,
  ORDER_CLICKS_COUNT = 15,
  WEIGHTED_KEY_REL = 13,
  WEIGHTED_KEY_REL_REPORT = 7,
  QUERIES_IN_THIS_REPORT = 23,
  BENCHMARK_QUERIES_IN_THIS_REPORT = 21,
  BENCHMARK_PRODUCT_QUERIES_IN_THIS_REPORT = 26,
  AVERAGE_CTR_BY_POSITION = 24,
  ORDER_AVERAGE_CTR_BY_POSITION = 28,
  BENCHMARK_POTENTIAL_TRAFFIC = 20,
  BENCHMARK_PRODUCT_POTENTIAL_TRAFFIC = 25,
  BENCHMARK_MISSED_TRAFFIC = 22,
  BENCHMARK_PRODCUT_MISSED_TRAFFIC = 27
}

export enum ReportType {
  BELOW_BENCHMARK_CTR = 8,
  ABOVE_BENCHMARK_CTR = 9,
  CTR_DROP_IMPACT = 7
}

export type ApiReportClient = {
  id: number
  name: string
}

export type ApiReportSite = {
  id: number
  url?: string
}

export type ApiReportType = {
  id: ReportType
  name: string
}

export type ApiReportSetting = {
  id: number
  name: string
  key: string
  value: string
}

export enum ApiReportStatus {
  DRAFT = "draft",
  COMPLETED = "completed",
  FAILED = "failed",
  CANCELED = "cancelled",
  PROCESSING = "processing"
}

export enum ApiReportDataType {
  EASY_TO_RANK = "easy_to_rank_keywords_report",
  UNUSED_REQUESTS_REPORTS_GROUP_ONE = "unused_requests_reports_group_one",
  UNSTABLE_KEYWORDS = "unstable_keywords",
  LOST_KEYWORDS = "lost_keywords",
  HIGH_VOLUME_DECLINED_KEYWORDS = "high_volume_declined_keywords"
}

export type ApiReportsGetReportByIdPayload = {
  id: ApiReport["uuid"]
}

export type ApiReportsGetReportGroupByIdPayload = ApiReportsGetReportByIdPayload
export type ApiReportsGetReportGroupFileByIdPayload = {
  file_type: "xlsx" | "csv"
}

export type ApiReportData = {
  position: string
  clicks: number
  ctr: string
  impressions: number
  lost_traffic: string
  position_diff: string
  best_position: string
  best_position_clicks: number
  best_position_impressions: number
  best_position_ctr: string
  query_name: string
  query_link: string
  query?: string
  difference_ctr: string
  clicks_in_year: number
  best_position_start_date: Date | string | null
  best_position_end_date: Date | string | null
  key_rel: number | null
  missed_traffic: string
  average_ctr: string
}

export type ApiReportsFilter = {
  type: string
  key: string
  title: string
  value: string
}

export type ApiReportsQueryParams = ApiQueryParams<ApiReport>
export type ApiReportDataQueryParams = {
  id: ApiReport["uuid"]
  params: ApiQueryParams<ApiReportData & { without_impressions: string }>
}

export type ApiReportsSerializer = (Reports: ApiReport[] | undefined) => ApiReport[] | undefined
export type ApiReportSerializer = (client: ApiReport | undefined) => ApiReport | undefined
export type ApiReportsResponse = ApiResponse<ApiReport[]>
export type ApiReportsTypesResponse = ApiResponse<ApiReportType[]>
export type ApiReportDataResponse = ApiResponse<ApiReportData[]> & {
  filters: ApiReportsFilter[]
  report: { type_id: number; type_key: ApiReportDataType }
}

export type Report = ApiReport
export type ReportData = ApiReportData
export type ReportGroup = ApiReportGroup
export type ReportMetric = ApiReportMetric
