import {
  ApiNotificationsQueryParams,
  ApiNotificationsReadNotificaitionsPayload,
  ApiNotificationsResponse,
  ApiNotificationsUnsubscribeResponse
} from "@/types/Notifications"
import { notificationsService } from "@/api/notifications/service"

export const useFetchUnsubscribeNotifications = async (
  cb: unknown = (v: unknown) => v
): Promise<ApiNotificationsUnsubscribeResponse | undefined> => {
  if (cb) {
    const res = (await notificationsService.unsubscribe()) as ApiNotificationsUnsubscribeResponse
    if (res) {
      return res as ApiNotificationsUnsubscribeResponse | undefined
    }
  }
  return (await notificationsService.unsubscribe()) as ApiNotificationsUnsubscribeResponse
}

export const useFetchSubscribeNotifications = async (): Promise<ApiNotificationsUnsubscribeResponse | undefined> => {
  return (await notificationsService.subscribe()) as ApiNotificationsUnsubscribeResponse
}

export const useFetchGetAllNotifications = async (params?: ApiNotificationsQueryParams): Promise<ApiNotificationsResponse> => {
  return (await notificationsService.findAll(params)) as ApiNotificationsResponse
}

export const useFetchPostReadNotifications = async (
  ids?: ApiNotificationsReadNotificaitionsPayload
): Promise<ApiNotificationsUnsubscribeResponse> => {
  return (await notificationsService.readNotifications(ids)) as ApiNotificationsUnsubscribeResponse
}
