import { ApiPayment, ApiPaymentsQueryParams, ApiPaymentsResponse, ApiPaymentsSerializer, ApiPaymentsUrl } from "@/types/Payments"
import { paymentsService } from "@/api/payments/service"
import { payProGlobalService } from "@/api/payments/payProService"

export const useFetchPayments = async (
  params: ApiPaymentsQueryParams = {},
  cb: ApiPaymentsSerializer = (v) => v
): Promise<ApiPaymentsResponse | undefined> => {
  if (cb) {
    const res = (await paymentsService.findAll(params)) as ApiPaymentsResponse
    if (res) {
      res.data = cb(res.data) as ApiPayment[]
      return res as ApiPaymentsResponse | undefined
    }
  }
  return (await paymentsService.findAll(params)) as ApiPaymentsResponse
}

export const useFetchPaymentUrl = async (payload: ApiPaymentsUrl) => {
  return await payProGlobalService.getPaymentUrl(payload)
}
