import { defineComponent, h, PropType } from "vue"
import { Input as AInput } from "ant-design-vue"
import "./style.scss"

export default defineComponent({
  props: {
    modelValue: {
      type: String as PropType<string>,
      default: () => ""
    },
    placeholder: {
      type: String,
      default: "Placeholder"
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const onClickClear = () => {
      emit("update:modelValue", "")
    }
    const clear = () =>
      h("div", { class: "cursor-pointer text-rock-blue", onClick: onClickClear }, h("i", { class: "buddler-icon-clear" }))

    return () =>
      h(
        AInput,
        {
          class: "buddler-input fs-6 lh-6 rounded-3",
          value: props.modelValue,
          "onUpdate:value": (value: string) => emit("update:modelValue", value),
          placeholder: "Start typing the address...",
          size: "small"
        },
        {
          suffix: () => (props.modelValue ? clear() : null)
        }
      )
  }
})
