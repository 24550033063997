import { Controller } from "@/api/decorators"
import BaseController from "@/api/base-controller"
import { ApiClientService } from "@/api/client/service"
import { ApiClient } from "@/types/Client"

@Controller(ApiClientService)
class ClientController extends BaseController<ApiClientService> {
  public async getProfile(): Promise<ApiClient | undefined | void> {
    return this.service.getProfile()
  }
}

export const clientController = new ClientController()
