interface ControllerMetadata<T> {
  service: T
}
export default class BaseController<T> {
  public service: T = {} as T

  constructor() {
    const metadata: ControllerMetadata<T> = Reflect.getMetadata("controllerMetadata", this.constructor)
    if (metadata && metadata.service) {
      this.service = metadata.service
    }
  }
}
