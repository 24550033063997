import { Get, PathParam, QueryParams, Serialize, Service } from "@/api/decorators"
import { ApiSite, ApiSiteCountries, ApiSitesQueryParams, ApiSitesResponse } from "@/types/Sites"

const count = 0
@Service({
  path: "sites"
})
export class ApiSitesService {
  @Get()
  // @Serialize((sites: ApiSitesResponse) => {
  //   count += 1
  //   return {
  //     ...sites,
  //     // data: [],
  //     updating: count !== 10
  //   }
  // })
  public async findAll(@QueryParams() params?: ApiSitesQueryParams): Promise<ApiSitesResponse | undefined | void> {}

  @Get("/:id")
  public async findOne(@PathParam("id") id: string): Promise<ApiSite | undefined | void> {}

  @Get("/:id/countries")
  public async findOneSiteCountries(@PathParam("id") id: string): Promise<ApiSiteCountries | undefined | void> {}
}
