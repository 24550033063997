import { ApiSitesService } from "@/api/sites/service"
import { Controller } from "@/api/decorators"
import BaseController from "@/api/base-controller"
import { ApiSite, ApiSiteCountries, ApiSitesQueryParams, ApiSitesResponse } from "@/types/Sites"

@Controller(ApiSitesService)
class ApiSitesController extends BaseController<ApiSitesService> {
  public async findAll(params?: ApiSitesQueryParams): Promise<ApiSitesResponse | undefined | void> {
    return this.service.findAll(params)
  }

  public async findOne(id: string | number): Promise<ApiSite | undefined | void> {
    return this.service.findOne(`${id}`)
  }

  public async findOneSiteCountries(id: string | number): Promise<ApiSiteCountries | undefined | void> {
    return this.service.findOneSiteCountries(`${id}`)
  }

  public async findAllUnavailable(params?: ApiSitesQueryParams): Promise<ApiSitesResponse | undefined | void> {
    return this.service.findAll({ ...params, "filter[launch_available]": "false" })
  }
}

export const sitesController = new ApiSitesController()
