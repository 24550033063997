import { Site } from "@/types/Sites"
import { ref } from "vue"
import { sitesController } from "@/api/sites/controller"

const allSites = ref<Site[]>()

export const useAllSites = () => {
  const setAllSites = (newSites: Site[]) => {
    allSites.value = newSites
    return allSites
  }

  const requestAllSites = async () => {
    const allSitesRes = await sitesController.findAll({ per_page: 1000 })
    if (allSitesRes && allSitesRes.data) {
      setAllSites(allSitesRes.data)
    }
  }

  return {
    allSites,
    setAllSites,
    requestAllSites
  }
}
