import { createRouter, createWebHistory, RouteLocationNormalizedLoaded } from "vue-router"
import { useDiscounts } from "@/composables/useDiscounts"
// import GoogleAuthCallback from "@/components/GoogleAuthCallback/index"

export enum Routes {
  MAIN = "/",
  SITES = "/sites",
  CONFIRM = "/confirm",
  GOOGLE = "/auth/google/callback",
  SIGN_IN = "/sign-in",
  UNSUBSCRIBE = "/unsubscribe",
  FREE_BONUS = "/gifts",
  CONTENT_VITAMINAZER = "/content-vitaminizer"
}
export enum RoutesName {
  SINGLE_REPORT = "Single Report",
  ALL_REPORTS = "All Reports",
  CONFIRM = "Confirm",
  GOOGLE_CALLBACK = "Google Callback",
  SIGN_IN_ERROR = "Sign In Error",
  UNSUBSCRIBE = "Unsubscribe",
  FREE_BONUS_LIMIT = "Free Bonus Limit",
  CONTENT_VITAMINAZER = "Content Vitaminizer"
}

const routes = [
  {
    path: Routes.MAIN,
    name: "Main",
    component: () => import("@/components/Layout/index.vue"),
    redirect: Routes.SITES,
    children: [
      {
        path: Routes.SITES,
        component: () => import("@/views/Sites.vue")
      },
      {
        path: `${Routes.SITES}/:id/reports`,
        name: RoutesName.ALL_REPORTS,
        component: () => import("@/views/SingleSiteReports.vue")
      },
      {
        path: `${Routes.SITES}/:id/reports/:reportGroupId`,
        name: RoutesName.SINGLE_REPORT,
        component: () => import("@/views/SingleGroupReport.vue")
      },
      {
        path: `${Routes.CONTENT_VITAMINAZER}`,
        name: RoutesName.CONTENT_VITAMINAZER,
        component: () => import("@/views/ContentVitaminazer.vue")
      }
    ]
  },
  {
    path: Routes.CONFIRM,
    name: RoutesName.CONFIRM,
    component: () => import("@/pages/Confirm.vue")
  },
  {
    path: Routes.GOOGLE,
    name: RoutesName.GOOGLE_CALLBACK,
    component: () => import("@/components/GoogleAuthCallback/index")
  },
  {
    path: Routes.SIGN_IN,
    name: RoutesName.SIGN_IN_ERROR,
    component: () => import("@/pages/SignInError.vue")
  },
  {
    path: Routes.UNSUBSCRIBE,
    name: RoutesName.UNSUBSCRIBE,
    component: () => import("@/pages/Unsubscribe.vue")
  },
  {
    path: Routes.FREE_BONUS,
    name: RoutesName.FREE_BONUS_LIMIT,
    component: () => import("@/components/FreeBonusLimit/index"),
    beforeEnter: (to: RouteLocationNormalizedLoaded, from: any, next: any) => {
      if (to.query.key) {
        const { setNeedCheckDiscountsInLocalStorage } = useDiscounts()
        setNeedCheckDiscountsInLocalStorage(to.query.key as string)
      }
      next(Routes.SITES)
    }
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
