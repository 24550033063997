import { computed, defineComponent, h, ref } from "vue"
import { SkeletonItem } from "@/components/UI"

export default defineComponent({
  props: {
    total: {
      type: Number,
      required: false,
      default: undefined
    },
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 50
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ["change-page", "change-per-page"],
  setup(props, { emit }) {
    const perPage = ref({ value: props.perPage, label: props.perPage })

    const onClickPage = (pageNumber: number) => () => {
      emit("change-page", pageNumber)
    }

    const isCurrenPage = (pageNumber: number): boolean => {
      return pageNumber === props.page
    }

    const li = (page: number, text?: string) =>
      h(
        "li",
        {
          class: `page-item fs-7 fw-semibold lh-12 px-4 py-2 rounded-3 cursor-pointer  ${
            isCurrenPage(page) ? "text-white bg-dodger-blue-epsilon" : "text-dodger-blue-epsilon text-tory-blue-hover"
          }`,
          onClick: onClickPage(page)
        },
        h("span", { class: `page-link` }, [text || page])
      )

    const notPageLi = () =>
      h(
        "li",
        { class: `page-item d-flex align-items-center px-4 py-3 rounded-3` },
        h("span", { class: `page-link fs-15px text-rock-blue` }, ["..."])
      )

    const pagesCount = computed<number>(() => {
      if (props.total !== undefined) {
        if (props.total <= +perPage.value.value) {
          return 1
        }
        return Math.ceil(props.total / +perPage.value.value)
      }
      return 0
    })

    const lastPage = () => {
      return li(pagesCount.value)
    }

    const firstPage = () => {
      return li(1)
    }

    const firstThreePages = () => {
      const arr = []
      for (let i = 1; i <= 3; i++) {
        arr.push(li(i))
      }
      return arr
    }

    const nextTwoPages = () => {
      return [li(props.page + 1), li(props.page + 2)]
    }

    const prevTwoPages = () => {
      return [li(props.page - 2), li(props.page - 1)]
    }

    const nextButton = () => {
      return h(li(props.page + 1, "Next"))
    }

    const prevButton = () => {
      return h(li(props.page - 1, "Previous"))
    }

    const pages = () => {
      if (pagesCount.value < 3) {
        const result = []
        for (let i = 1; i <= pagesCount.value; i++) {
          result.push(li(i))
        }

        return result
      }

      if (pagesCount.value === 3) {
        if (props.page === 1) {
          return [firstThreePages(), nextButton()]
        }
        if (props.page === pagesCount.value) {
          return [prevButton(), firstThreePages()]
        }
        return [prevButton(), firstThreePages(), nextButton()]
      }

      if (props.page < 3) {
        if (props.page === 1) {
          return [firstThreePages(), notPageLi(), lastPage(), nextButton()]
        }
        return [prevButton(), firstThreePages(), notPageLi(), lastPage(), nextButton()]
      }

      if (props.page === 3 && props.page !== pagesCount.value - 1) {
        return [prevButton(), firstThreePages(), li(4), notPageLi(), lastPage(), nextButton()]
      }

      if (props.page === 3 && pagesCount.value === 4) {
        return [prevButton(), firstPage(), notPageLi(), li(props.page - 1), li(props.page), lastPage(), nextButton()]
      }

      if (props.page === pagesCount.value - 2) {
        return [prevButton(), firstPage(), notPageLi(), ...prevTwoPages(), li(props.page), ...nextTwoPages(), nextButton()]
      }

      if (props.page === pagesCount.value - 1) {
        return [prevButton(), firstPage(), notPageLi(), ...prevTwoPages(), li(props.page), lastPage(), nextButton()]
      }

      if (props.page === pagesCount.value) {
        return [prevButton(), firstPage(), notPageLi(), ...prevTwoPages(), lastPage()]
      }

      return [
        prevButton(),
        firstPage(),
        notPageLi(),
        ...prevTwoPages(),
        li(props.page),
        ...nextTwoPages(),
        notPageLi(),
        lastPage(),
        nextButton()
      ]
    }

    return () => {
      if (props.total === undefined || props.isLoading) {
        return h("div", { class: "d-flex gap-5 align-items-center" }, [
          h("div", { style: { height: "31px", width: "23px" } }, h(SkeletonItem, { rounded: "2" })),
          h("div", { class: "d-flex gap-9" }),
          Array.from({ length: 3 }, () =>
            h("div", { style: { width: "9px", height: "15px" } }, h(SkeletonItem, { rounded: "2" }))
          )
        ])
      } else {
        return pagesCount.value > 1
          ? h("div", { class: "d-flex mt-2 align-items-center" }, [
              h("nav", h("ul", { class: "pagination m-0 p-0 d-flex" }, pages()))
            ])
          : null
      }
    }
  }
})
