import { computed, ref, watch } from "vue"
import { useFetchPaymentUrl } from "@/api/payments"

const visible = ref(false)
const orderUuid = ref<string>()
const paymentUrl = ref<string>()

const requestPaymentUrl = async () => {
  if (orderUuid.value) {
    const res = await useFetchPaymentUrl({ order_uuid: orderUuid.value })
    if (res) {
      paymentUrl.value = res.url
    }
  }
}
export const usePaymentModal = () => {
  const isOpen = computed<boolean>(() => {
    return visible.value
  })

  const openModal = (uuid: string) => {
    visible.value = true
    orderUuid.value = uuid
  }

  const closeModal = () => {
    visible.value = false
    orderUuid.value = undefined
  }

  const toggleModal = (uuid: string) => {
    if (isOpen.value) {
      closeModal()
    } else {
      openModal(uuid)
    }
  }

  return {
    visible,
    openModal,
    closeModal,
    toggleModal,
    paymentUrl
  }
}

watch(orderUuid, async () => {
  await requestPaymentUrl()
})
