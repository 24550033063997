import { AppContext, createVNode, render } from "vue"
import NotificationComponent from "./component.vue"
import { Notification } from "@/types/Notifications"

export const notify = {
  open: (notification: Notification, context: AppContext | null) => {
    const container = document.getElementById("notification-container")
    if (container) {
      const div = document.createElement("div")
      container.appendChild(div)
      const vm = createVNode(NotificationComponent, { notification, key: notification.id || new Date().getTime() })
      const event = new CustomEvent(notification.type, {
        detail: {
          notification: notification
        }
      })
      vm.appContext = context
      window.dispatchEvent(event)
      render(vm, div)
    }
  },
  audio: async () => {
    const audio = new Audio("/uvedomlenie.mp3")
    audio.muted = true
    audio.autoplay = false
    await audio.play()
    await audio.pause()
  }
}
