import { Body, Get, PathParam, Post, QueryParams, Serialize, Service } from "@/api/decorators"
import {
  ApiVitaminDataPayload,
  ApiVitaminGeneratePayload,
  ApiVitaminGenerateResponse,
  ApiVitaminGenerationDataResponse,
  ApiVitaminGroupQueriesDataResponse,
  ApiVitaminLastGeneration,
  ApiVitaminQueriesDataResponse,
  ApiVitaminRecommendationResponse,
  ApiVitaminStatus,
  ApiVitaminUnigramDataResponse
} from "@/types/Vitamin"
import dayjs from "dayjs"

@Service({
  path: "tools/vitamin"
})
export class ApiVitaminService {
  @Get("/:id")
  @Serialize((item: ApiVitaminGenerationDataResponse): ApiVitaminGenerationDataResponse => {
    return {
      ...item,
      created_at: dayjs(item.created_at).format("MMMM D, YYYY, HH:mm")
    }
  })
  public async findOne(@PathParam("id") id: string): Promise<ApiVitaminGenerationDataResponse | undefined | void> {}

  @Get("/status")
  public async status(): Promise<ApiVitaminStatus | undefined | void> {}

  @Post()
  public async postGenerate(@Body() payload: ApiVitaminGeneratePayload): Promise<ApiVitaminGenerateResponse | undefined | void> {}

  @Get("/last")
  @Serialize((generation: ApiVitaminLastGeneration): ApiVitaminLastGeneration => {
    if (generation) {
      return {
        ...generation,
        created_at: dayjs(generation.created_at).format("MMMM D, YYYY, HH:mm")
      }
    }
    return generation
  })
  public async findLast(): Promise<ApiVitaminLastGeneration | undefined | void> {}

  @Get("/:id/queries-data")
  public async findQueriesData(
    @PathParam("id") id: string | number,
    @QueryParams() params?: ApiVitaminDataPayload
  ): Promise<ApiVitaminQueriesDataResponse | undefined | void> {}

  @Get("/:id/unigrams-data")
  public async findUnigramData(
    @PathParam("id") id: string | number,
    @QueryParams() params?: ApiVitaminDataPayload
  ): Promise<ApiVitaminUnigramDataResponse | undefined | void> {}

  @Get("/:id/group-queries-data")
  public async findGroupQueriesData(
    @PathParam("id") id: string | number,
    @QueryParams() params?: ApiVitaminDataPayload
  ): Promise<ApiVitaminGroupQueriesDataResponse | undefined | void> {}

  @Get("/recommendations")
  public async findRecommendation(): Promise<ApiVitaminRecommendationResponse | undefined | void> {}
}
