import { Get, QueryParams, Service } from "@/api/decorators"
import { ApiServices } from "@/api"
import {
  ApiAuthGoogleCallbackPayload,
  ApiAuthGoogleCallbackResponse,
  ApiAuthGoogleResponse,
  ApiAuthLogoutResponse
} from "@/types/Auth"

enum Modules {
  LOGOUT = "/logout",
  GOOGLE = "/google",
  GOOGLE_CALLBACK = "/google/callback"
}

@Service({
  path: "auth"
})
class ApiAuthService {
  @Get(Modules.GOOGLE)
  public async getGoogleAuthLink(): Promise<ApiAuthGoogleResponse | undefined | void> {}

  @Get(Modules.GOOGLE_CALLBACK)
  public async getGoogleCallback(
    @QueryParams() payload: ApiAuthGoogleCallbackPayload
  ): Promise<ApiAuthGoogleCallbackResponse | undefined | void> {}

  @Get(Modules.LOGOUT)
  public async logout(): Promise<ApiAuthLogoutResponse | undefined | void> {}
}

export const authService = new ApiAuthService()
