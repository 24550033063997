import { ref } from "vue"
import { SortItem } from "@/types/common"

const sortItems = [
  {
    id: "name",
    label: "by name"
  },
  {
    id: "last_audit",
    label: "with newest audits"
  },
  {
    id: "-last_audit",
    label: "with oldest audits"
  },
  {
    id: "clicks_per_year",
    label: "by lowest clicks (per year)"
  },
  {
    id: "-clicks_per_year",
    label: "by highest clicks (per year)"
  }
]

const sort = ref<SortItem>()

export const useSitesSort = () => {
  const setSort = (newSort: SortItem) => {
    sort.value = newSort
  }

  const setSortToLocalStorage = () => {
    localStorage.setItem("sites_sort", `${sort.value?.id}`)
  }

  const initSortFromLocalStorage = () => {
    const sortFromLocalStorage = localStorage.getItem("sites_sort")
    const s = sortItems.find((s) => s.id === sortFromLocalStorage)
    if (s) {
      setSort(s)
    } else {
      setSort(sortItems[0])
    }
  }

  return {
    sort,
    sortItems,
    setSort,
    setSortToLocalStorage,
    initSortFromLocalStorage
  }
}
