<template>
  <div class="content-vitaminazer-how-it-work">
    <div class="content-vitaminazer-how-it-work__header">
      <div class="content-vitaminazer-how-it-work__title">How AI Content Vitaminizer works</div>
    </div>

    <div class="content-vitaminazer-how-it-work__timeline row row-cols-1 row-cols-lg-5 g-0 gy-14">
      <div class="content-vitaminazer-how-it-work__timeline__line" />
      <div class="content-vitaminazer-how-it-work__timeline__item col">
        <div class="content-vitaminazer-how-it-work__timeline__item__number">01</div>
        <div class="content-vitaminazer-how-it-work__timeline__item__text">
          <div class="mb-0 mb-lg-3">Enter the URL of the page you want to optimize.</div>
          <div class="pt-4"><b>Your website must be accessible in your GSC account.</b></div>
        </div>
      </div>
      <div class="content-vitaminazer-how-it-work__timeline__item col">
        <div class="content-vitaminazer-how-it-work__timeline__item__number">02</div>
        <div class="content-vitaminazer-how-it-work__timeline__item__text">
          <div class="mb-0 mb-lg-3">
            We analyze GSC data to identify promising queries for which this page is already appearing in Google
          </div>
        </div>
      </div>
      <div class="content-vitaminazer-how-it-work__timeline__item">
        <div class="content-vitaminazer-how-it-work__timeline__item__number">03</div>
        <div class="content-vitaminazer-how-it-work__timeline__item__text">
          <div class="mb-0 mb-lg-3">
            We check how well the text content on your page is optimized for the most promising queries
          </div>
        </div>
      </div>
      <div class="content-vitaminazer-how-it-work__timeline__item col">
        <div class="content-vitaminazer-how-it-work__timeline__item__number">04</div>
        <div class="content-vitaminazer-how-it-work__timeline__item__text">
          <div class="mb-0 mb-lg-3">
            Using <b>Hitchhiker Keywords for SEO</b>, our AI generates supplementary texts for your page
          </div>
        </div>
      </div>
      <div class="content-vitaminazer-how-it-work__timeline__item col bg-white-lilac">
        <div class="content-vitaminazer-how-it-work__timeline__item__number green">05</div>
        <div class="content-vitaminazer-how-it-work__timeline__item__text">
          <div>
            <div class="mb-5">Get your optimized content.</div>
            <b>Review it</b>, add to the page and start gaining additional organic traffic from Google.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped lang="scss">
.content-vitaminazer-how-it-work {
  &__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 40px;
    @include lg() {
      font-size: 22px;
      line-height: normal;
      margin-bottom: 32px;
    }
  }

  &__timeline {
    position: relative;
    justify-content: space-between;

    &__line {
      width: 85%;
      height: 2px;
      background: var(--bs-dodger-blue-epsilon);
      position: absolute;
      top: 22px;
      left: 0;
      z-index: 0;
      @include lg() {
        transform-origin: left;
        transform: rotate(90deg);
        left: 18px;
        top: 0;
        width: 120%;
        max-width: 375px;
        height: 1px;
      }
    }

    &__item {
      position: relative;
      max-width: 192px;
      &:last-child {
        z-index: 2;
      }
      @include lg() {
        max-width: none;
        display: flex;
        gap: 16px;
      }
    }

    &__item__number {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: var(--bs-dodger-blue-epsilon);
      color: var(--bs-white-alfa);
      font-size: 22px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 22px;
      z-index: 1;
      position: relative;
      &.green {
        background: var(--bs-emerald-alfa);
      }
      @include lg() {
        flex-shrink: 0;
        margin-bottom: 0;
        width: 36px;
        height: 36px;
        font-size: 18px;
      }
    }
    &__item__text {
      max-width: 192px;
      line-height: 20px;
      @include lg() {
        line-height: 16px;
        max-width: 265px;
      }
    }
  }
}
</style>
