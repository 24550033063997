import { SiteCardTypes } from "@/types/common"
import { ApiSiteScheme } from "@/types/Sites"

export const getIconNameForSiteScheme = (scheme: ApiSiteScheme): string => {
  const map: Record<ApiSiteScheme, "domain" | "lock" | "warning"> = {
    [ApiSiteScheme.HTTP]: "warning",
    [ApiSiteScheme.HTTPS]: "lock",
    [ApiSiteScheme.HTTPS_WWW]: "lock",
    [ApiSiteScheme.DOMAIN]: "domain"
  }
  return map[scheme]
}

export const getIconColorForSiteScheme = (scheme: ApiSiteScheme): string => {
  const map: Record<ApiSiteScheme, string> = {
    [ApiSiteScheme.HTTP]: "punch-alfa",
    [ApiSiteScheme.HTTPS]: "emerald-alfa",
    [ApiSiteScheme.HTTPS_WWW]: "emerald-alfa",
    [ApiSiteScheme.DOMAIN]: "dodger-blue-epsilon"
  }
  return map[scheme]
}
export const getBackgroundColorForSiteScheme = (scheme: ApiSiteScheme): string => {
  const map: Record<ApiSiteScheme, string> = {
    [ApiSiteScheme.HTTP]: "punch-betta",
    [ApiSiteScheme.HTTPS]: "solitude",
    [ApiSiteScheme.HTTPS_WWW]: "solitude",
    [ApiSiteScheme.DOMAIN]: "solitude"
  }
  return map[scheme]
}

export const getIconForSiteScheme = (scheme: ApiSiteScheme) => {
  const map: Record<ApiSiteScheme, { name: string; color: string; bg: string }> = Object.fromEntries(
    Object.values(ApiSiteScheme).map((schemeName: ApiSiteScheme) => {
      return [
        schemeName,
        {
          name: getIconNameForSiteScheme(schemeName),
          color: getIconColorForSiteScheme(schemeName),
          bg: getBackgroundColorForSiteScheme(schemeName)
        }
      ]
    })
  ) as Record<ApiSiteScheme, { name: string; color: string; bg: string }>
  return map[scheme]
}
import { ApiSiteLaunchStatus, Site } from "@/types/Sites"

export const checkType = (currentType: SiteCardTypes, sourceType: SiteCardTypes): boolean => {
  return currentType === sourceType
}

export const checkStatus = (currentStatus: ApiSiteLaunchStatus, sourceStatus: ApiSiteLaunchStatus): boolean => {
  return currentStatus === sourceStatus
}

export const checkIsLimitType = (currentType: SiteCardTypes): boolean => {
  return checkType(currentType, SiteCardTypes.MIN_ALLOWED) || checkType(currentType, SiteCardTypes.MAX_ALLOWED)
}

export const checkIsCompletedToday = (currentType: SiteCardTypes): boolean => {
  return checkType(currentType, SiteCardTypes.COMPLETED_TODAY)
}

export const checkIsFirstAudit = (currentType: SiteCardTypes): boolean => {
  return checkType(currentType, SiteCardTypes.FIRST_AUDIT)
}

export const checkIsUpdating = (currentType: SiteCardTypes): boolean => {
  return checkType(currentType, SiteCardTypes.UPDATING)
}

export const isUpdatingSite = (site: Site): boolean => {
  return checkStatus(site.launch.status, ApiSiteLaunchStatus.UPDATING)
}

export const isUnavailableSite = (site: Site): boolean => {
  return checkStatus(site.launch.status, ApiSiteLaunchStatus.UNAVAILABLE)
}

export const isAvailableSite = (site: Site): boolean => {
  return checkStatus(site.launch.status, ApiSiteLaunchStatus.AVAILABLE)
}
export const isFailedSite = (site: Site): boolean => {
  return site.orders.has_last_failed
}

export const isMinAllowedSite = (site: Site): boolean => {
  return site.clicks_per_year <= 5000
}

export const isMaxAllowedSite = (site: Site): boolean => {
  return site.clicks_per_year >= 15000000
}

export const isProcessingSite = (site: Site): boolean => {
  return checkStatus(site.launch.status, ApiSiteLaunchStatus.PROCESSING)
}

export const isPendingSite = (site: Site): boolean => {
  return checkStatus(site.launch.status, ApiSiteLaunchStatus.PENDING)
}

export const isHasCompletedSite = (site: Site): boolean => {
  return site.orders.has_completed
}

export const isProcessingWithCompletedSite = (site: Site): boolean => {
  return isProcessingSite(site) && isHasCompletedSite(site)
}

export const isHasUnreadSite = (site: Site): boolean => {
  return site.orders.has_unread
}

export const isNewAuditSite = (site: Site): boolean => {
  return isHasUnreadSite(site) && !isProcessingSite(site)
}

export const isNewSite = (site: Site): boolean => {
  return site.is_new
}

export const isDefaultSite = (site: Site): boolean => {
  return !isProcessingSite(site) && isHasCompletedSite(site)
}

export const isFirstAuditSite = (site: Site): boolean => {
  return !isProcessingSite(site) && !isHasCompletedSite(site) && !isHasUnreadSite(site)
}

export const getSiteTitleBadgeText = (site: Site): string | undefined => {
  if (isNewAuditSite(site)) {
    return "New audit received"
  }
  if (isNewSite(site)) {
    return "New project from GSC"
  }
}

export const checkIsNotAccess = (currentType: SiteCardTypes): boolean => {
  return checkType(currentType, SiteCardTypes.NOT_ACCESS)
}
