import { Get, QueryParams, Service } from "@/api/decorators"
import { ApiPaymentsQueryParams, ApiPaymentsResponse } from "@/types/Payments"

@Service({
  path: "payments"
})
class ApiPaymentsService {
  @Get()
  public async findAll(@QueryParams() params: ApiPaymentsQueryParams): Promise<ApiPaymentsResponse | undefined | void> {}
}

export const paymentsService = new ApiPaymentsService()
