<template>
  <p class="mb-3">
    The site's textual relevance to the query, i.e. how well the text content on the site's landing page is optimized.
  </p>
  <span class="fs-6 lh-8 text-start">Learn more in the tutorial </span> <br />
  <a href="https://buddler.com/tutorials/easy-to-rank-keywords-report-2" target="_blank" class="fw-medium lh-8 fs-6">
    Easy to Rank Keywords Report
  </a>
</template>
<script setup lang="ts"></script>

<style scoped lang="scss"></style>
