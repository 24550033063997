import { ApiQueryParams, ApiResponse } from "@/api/types"

export type ApiSite = {
  id: number
  name: string
  scheme: ApiSiteScheme
  created_at: Date | string | null
  updated_at: Date | string | null
  size: string
  rating: ApiSiteRating
  orders: ApiSiteOrder
  is_new: boolean
  clicks_per_year: number
  launch: ApiSiteLaunch
  has_data: boolean | null | undefined
}

export type ApiSiteLaunch = {
  country: ApiSiteCountryItem | null
  status: ApiSiteLaunchStatus
  message: string
}

export enum ApiSiteLaunchStatus {
  UPDATING = "updating",
  UNAVAILABLE = "unavailable",
  PROCESSING = "processing",
  PENDING = "pending",
  AVAILABLE = "available"
}

export enum ApiSiteScheme {
  HTTP = "http",
  HTTPS = "https",
  DOMAIN = "sc-domain",
  HTTPS_WWW = "https://www."
}
export enum ApiSiteSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small"
}

type ApiSiteOrderLast = {
  date: string
  country_name: string
}

export type ApiSiteOrder = {
  has_active: boolean
  has_completed: boolean
  has_unread: boolean
  has_last_failed: boolean
  has_completed_today: boolean
  last: ApiSiteOrderLast
  count: number
}

export type ApiSitePivot = {
  permission_level: string
  has_access: boolean
  created_at: Date | string | null
  updated_at: Date | string | null
}

export type ApiSiteRating = {
  value: number
  is_actual: boolean
  updated_at: Date | string | null
}

export type ApiSiteCountryItem = {
  id: number
  name: string
  code: string
}

export type ApiSiteCountry = {
  clicks: number
  clicks_percentage: number
  available_for_audit: boolean
  available_for_launch: boolean
  country: ApiSiteCountryItem
}

export type ApiSiteCountries = ApiSiteCountry[]

export type ApiSitesQueryParams = ApiQueryParams<ApiSite & { launch_available: boolean; last_audit: string }>

export type Site = ApiSite
export type ApiSitesSerializer = (items: ApiSite[] | undefined) => ApiSite[] | undefined
export type ApiSiteSerializer = (item: ApiSite | undefined) => ApiSite | undefined
export type ApiSitesResponse = ApiResponse<ApiSite[]> & { updating: boolean }
export type SiteCountries = ApiSiteCountries
