import { ref } from "vue"
import { ApiSiteCountryItem } from "@/types/Sites"

type DateItem = {
  id: string | number
  date: string
  country: ApiSiteCountryItem
}

const currentDate = ref<DateItem>()
export const useCurrentAuditDate = () => {
  return {
    currentDate
  }
}
