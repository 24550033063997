import axios, { AxiosResponse, ResponseType } from "axios"
import { AxiosError } from "axios/index"
import { useErrorException } from "@/composables/useErrorException"
import { ApiAuthGoogleCallbackResponse } from "@/types/Auth"

export const client = axios.create({
  baseURL: process.env.VUE_APP_CORE_API_URL,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true,
  withXSRFToken: true
})

export const buildGetRequest = <T = unknown>(url: string, responseType?: ResponseType) => {
  return async (query?: object) => {
    try {
      const res = await client.get<T>(url, {
        params: query,
        responseType: responseType || "json"
      })
      if (res && checkResponse(res)) {
        return res.data
      }
    } catch (e) {
      const error = e as AxiosError<{ message: string } | ApiAuthGoogleCallbackResponse>
      const { error: _e } = useErrorException()
      _e.value = error
    }
  }
}

export const buildPostRequest = <T = unknown, B extends object = object>(url: string) => {
  return async (body: B) => {
    const res = await client.post<T>(url, body, { headers: { "Content-Type": "application/json" } })
    if (res && checkResponse(res)) {
      return res.data || ({} as T)
    }
  }
}

export const buildDeleteRequest = <T = unknown>(url: string) => {
  return async () => {
    const res = await client.delete<T>(url)
    if (res && checkResponse(res)) {
      return true
    }
  }
}

export const buildPutRequest = <T = unknown, B extends object = object>(url: string) => {
  return async (body: B) => {
    const res = await client.put<T>(url, body, { headers: { "Content-Type": "application/json" } })
    if (res && checkResponse(res)) {
      return res.data
    }
  }
}

export const buildPatchRequest = <T = unknown, B extends object = object>(url: string) => {
  return async (body: B) => {
    const res = await client.patch<T>(url, body, { headers: { "Content-Type": "application/json" } })
    if (res && checkResponse(res)) {
      return res.data
    }
  }
}

export const checkResponse = <T>(response: AxiosResponse<T, unknown> | undefined) => {
  return response && (response.status === 200 || response.status === 204 || response.status === 201 || response.status === 202)
}

export enum ApiServices {
  AUTH = "auth",
  USERS = "users",
  SITES = "sites",
  AGREEMENTS = "agreements",
  TARIFFS = "tariffs",
  CLIENTS = "clients",
  SETTINGS = "settings",
  ORDERS = "orders",
  PAYMENTS = "payments",
  PAYPRO_GLOBAL = "paypro-global",
  PRODUCTS = "products",
  REPORTS = "reports",
  NOTIFICATIONS = "notifications",
  DISCOUNTS = "discounts"
}

// eslint-disable-next-line
export type ApiServiceMethod<T> = (...args: any[]) => Promise<T>
export type ApiServiceAction<T, P extends object = object> = {
  type: T
  payload?: P
}
export type ApiService<A extends string, S> = Record<A, S>
